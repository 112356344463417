import * as React from 'react';
import PropTypes from 'prop-types';
import InfiniteLoader from 'react-window-infinite-loader';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import {
    Autocomplete,
    autocompleteClasses,
    Box,
    Checkbox,
    ListSubheader,
    ListItem,
    ListItemText,
    Popper,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';

const LISTBOX_PADDING = 8; // px
const LOADING = 1;
const LOADED = 2;
let itemStatusMap = {};
const isItemLoaded = (index) => {
    return !!itemStatusMap[index];
};
const loadMoreItems = (startIndex, stopIndex) => {
    // Set the status of items to LOADING which are not fetched
    for (let index = startIndex; index <= stopIndex; index++) {
        itemStatusMap[index] = LOADING;
    }

    return new Promise((resolve) =>
        setTimeout(() => {
            // Once fetched, update the status to LOADED
            for (let index = startIndex; index <= stopIndex; index++) {
                itemStatusMap[index] = LOADED;
            }
            resolve();
        }, 100)
    );
};

const OuterElementContext = React.createContext({});
const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(
    function ListboxComponent(props, ref) {
        ListboxComponent.propTypes = {
            multipleSelection: PropTypes.bool,
            children: PropTypes.node,
            allOptionsWithoutAllOption: PropTypes.array,
            selectedOptions: PropTypes.array,
            var_values: PropTypes.array,
            set_values: PropTypes.func,
            var_values_nested_selected_options_key: PropTypes.string,
            groupOptionsFormat: PropTypes.bool,
            selectedGroups: PropTypes.object,
            setSelectedGroups: PropTypes.func,
            groupSelectionPostCallback: PropTypes.func,
            allOptionsSelected: PropTypes.bool,
            allowedItemsSelection: PropTypes.number,
        };

        let {
            children,
            allOptionsWithoutAllOption,
            selectedOptions,
            var_values,
            set_values,
            var_values_nested_selected_options_key,
            groupOptionsFormat,
            multipleSelection,
            allowedItemsSelection,
            selectedGroups,
            setSelectedGroups,
            allOptionsSelected,
            groupSelectionPostCallback,
            listMode,
            setListMode,
            ...other
        } = props;

        const itemData = [];
        children.forEach((item) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        });

        const theme = useTheme();
        const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
            noSsr: true,
        });

        const itemCount = itemData.length;
        const itemSize = smUp ? 36 : 48;

        const getChildSize = (child) => {
            if (child.hasOwnProperty('group')) {
                return 48;
            }
            return itemSize;
        };

        const getHeight = () => {
            if (itemCount > 8) {
                return 8 * itemSize;
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
        };

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={itemCount}
                        loadMoreItems={loadMoreItems}
                    >
                        {({ onItemsRendered, ref }) => (
                            <VariableSizeList
                                itemData={itemData}
                                height={getHeight() + 2 * LISTBOX_PADDING}
                                width="100%"
                                ref={ref}
                                outerElementType={OuterElementType}
                                innerElementType="ul"
                                itemSize={(index) =>
                                    getChildSize(itemData[index])
                                }
                                overscanCount={1}
                                itemCount={itemCount}
                                onItemsRendered={onItemsRendered}
                            >
                                {(props) => {
                                    const { data, index, style } = props;
                                    const dataSet = data[index];
                                    const inlineStyle = {
                                        ...style,
                                        top: style.top + LISTBOX_PADDING,
                                    };

                                    const isChecked = () => {
                                        /* 
                                            If All options are selected then all list items except "All" option are displayed as checked and disabled
                                            In order to allow user to perform custom user selection, the searched options should be displayed as unchecked and enabled
                                            */
                                        if (
                                            listMode === 'search' &&
                                            allOptionsSelected
                                        ) {
                                            return false;
                                        }
                                        // Display option as checked if option is among selected elements or if all elements are selected
                                        return (
                                            (selectedOptions &&
                                                selectedOptions.findIndex(
                                                    (selectedOption) =>
                                                        selectedOption.value ===
                                                        dataSet[1].value
                                                ) > -1) ||
                                            allOptionsSelected
                                        );
                                    };
                                    // Disable group selection in certain conditions i.e. Ungrouped data items,
                                    let allowGroupSelection =
                                        dataSet?.group &&
                                        dataSet.group.toLowerCase() !==
                                            'ungrouped' &&
                                        dataSet.group.toLowerCase() !== 'all'
                                            ? true
                                            : false;
                                    if ('group' in dataSet) {
                                        allowGroupSelection &&=
                                            selectedOptions &&
                                            selectedOptions.length +
                                                dataSet.children.length <=
                                                allowedItemsSelection;
                                    }

                                    const firstOptionFromList =
                                        groupOptionsFormat
                                            ? children[0].group
                                            : children[0][1].value;
                                    return itemStatusMap[index] === LOADED ? (
                                        dataSet.hasOwnProperty('group') ? (
                                            <ListSubheader
                                                {...dataSet[0]}
                                                component="div"
                                                key={index}
                                                style={{
                                                    ...inlineStyle,
                                                    ...(multipleSelection &&
                                                        allowGroupSelection &&
                                                        !allOptionsSelected && {
                                                            cursor: 'pointer',
                                                        }),
                                                }}
                                                {...(multipleSelection &&
                                                    allowGroupSelection &&
                                                    !allOptionsSelected && {
                                                        onClick: () => {
                                                            // Group is selected, unselect all option those options
                                                            if (
                                                                listMode ===
                                                                    'search' &&
                                                                allOptionsSelected
                                                            ) {
                                                                selectedOptions =
                                                                    allOptionsWithoutAllOption.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.group ===
                                                                            dataSet.group
                                                                    );
                                                                set_values(
                                                                    var_values_nested_selected_options_key !==
                                                                        null
                                                                        ? {
                                                                              ...var_values,
                                                                              [var_values_nested_selected_options_key]:
                                                                                  selectedOptions,
                                                                          }
                                                                        : selectedOptions
                                                                );
                                                                setListMode(
                                                                    'default'
                                                                );
                                                            } else {
                                                                if (
                                                                    dataSet.group in
                                                                        selectedGroups &&
                                                                    selectedGroups[
                                                                        dataSet
                                                                            .group
                                                                    ] === true
                                                                ) {
                                                                    selectedGroups[
                                                                        dataSet.group
                                                                    ] = false;
                                                                    selectedOptions =
                                                                        selectedOptions.filter(
                                                                            (
                                                                                selectedOption
                                                                            ) =>
                                                                                selectedOption.group !==
                                                                                dataSet.group
                                                                        );
                                                                }
                                                                // Otherwise either entire group is not selected or partially selected, select all options in group
                                                                else {
                                                                    selectedGroups[
                                                                        dataSet.group
                                                                    ] = true;
                                                                    selectedOptions =
                                                                        [
                                                                            ...selectedOptions.filter(
                                                                                (
                                                                                    selectedOption
                                                                                ) =>
                                                                                    selectedOption.group !==
                                                                                    dataSet.group
                                                                            ),
                                                                            ...dataSet.children.map(
                                                                                (
                                                                                    childElement
                                                                                ) =>
                                                                                    childElement[1]
                                                                            ),
                                                                        ];
                                                                }
                                                                // Check if selected options are being managed in key of var_values otherwise merge options in var_values directly
                                                                set_values(
                                                                    var_values_nested_selected_options_key !==
                                                                        null
                                                                        ? {
                                                                              ...var_values,
                                                                              [var_values_nested_selected_options_key]:
                                                                                  selectedOptions,
                                                                          }
                                                                        : selectedOptions
                                                                );
                                                            }
                                                            //set_values(var_values_nested_selected_options_key !== null ? ({ ...var_values, [var_values_nested_selected_options_key]: selectedOptions }) : ([...selectedOptions]));
                                                            // Function to call after group selection / unselection
                                                            if (
                                                                typeof groupSelectionPostCallback ===
                                                                'function'
                                                            ) {
                                                                groupSelectionPostCallback.apply(
                                                                    this,
                                                                    selectedOptions
                                                                );
                                                            }
                                                        },
                                                    })}
                                            >
                                                {dataSet.group !== 'all'
                                                    ? dataSet.group
                                                    : 'All Locations'}
                                            </ListSubheader>
                                        ) : (
                                            <ListItem
                                                key={index}
                                                {...dataSet[0]}
                                                component="div"
                                                style={inlineStyle}
                                            >
                                                {multipleSelection && (
                                                    <Checkbox
                                                        checked={isChecked()}
                                                        {...(index ===
                                                            (groupOptionsFormat
                                                                ? 1
                                                                : 0) &&
                                                            firstOptionFromList ===
                                                                'all' &&
                                                            selectedOptions.length && {
                                                                indeterminate:
                                                                    selectedOptions.length !==
                                                                    allOptionsWithoutAllOption.length +
                                                                        1
                                                                        ? true
                                                                        : false,
                                                            })}
                                                    />
                                                )}
                                                <ListItemText
                                                    primary={dataSet[1].label}
                                                    primaryTypographyProps={{
                                                        style: {
                                                            whiteSpace:
                                                                'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow:
                                                                'ellipsis',
                                                        },
                                                    }}
                                                    secondary={
                                                        dataSet[1].address
                                                    }
                                                    secondaryTypographyProps={{
                                                        style: {
                                                            whiteSpace:
                                                                'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow:
                                                                'ellipsis',
                                                        },
                                                    }}
                                                />
                                            </ListItem>
                                        )
                                    ) : (
                                        <ListItem
                                            key={index}
                                            {...dataSet[0]}
                                            component="div"
                                            style={inlineStyle}
                                        >
                                            <Typography>...Loading</Typography>
                                        </ListItem>
                                    );
                                }}
                            </VariableSizeList>
                        )}
                    </InfiniteLoader>
                </OuterElementContext.Provider>
            </div>
        );
    }
);

const StyledPopper = styled(Popper)({
    minWidth: '180px',
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

const mapFromLookupToComponentFormat = (options) =>
    options
        .map((group) => {
            if (group.value === 'all') {
                return group;
            } else {
                return group.locations.map((location) => ({
                    group: group.name,
                    value: location.value,
                    label: location.label,
                    address: location.address,
                    branch: location.branch,
                }));
            }
        })
        .flat();

export default function LocationsAutocompleteDropdownList({
    options,
    value,
    onChange,
    onClose,
    fieldLabel = 'Locations',
    var_values = [],
    set_values = null,
    groupByOptions,
    allowedItemsSelection = 20,
    lookup_options_format = false,
    var_values_nested_selected_options_key = null,
    multipleSelection = null,
    disableClearIcon = false,
    groupSelectionPostCallback = null,
    allSelectedByDefault = true,
    listMode = 'default',
    setListMode,
}) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const allOptions = lookup_options_format
        ? mapFromLookupToComponentFormat(options)
        : options;
    // List of options excluding with "Select All" option
    const allOptionsWithoutAllOption = allOptions.filter(
        (option) => option.value !== 'all'
    );
    // Condition check for all values selected in the options list
    const selectedAll =
        (value &&
            Array.isArray(value) &&
            value.length > 0 &&
            value[0].value === 'all') ||
        (value && value.length === allOptionsWithoutAllOption.length);

    // console.log('selected All ', selectedAll);
    // console.log('options ', allOptions);
    // console.log('value ', value);
    // console.log('groupByOptions ', groupByOptions);

    const [textFieldBlurred, setTextFieldBlurred] = React.useState(true);
    const [searchText, setSearchText] = React.useState('');
    const [selectedGroups, setSelectedGroups] = React.useState({});
    // Internal flag state set to true if all options are selected
    const [allOptionsSelected, setAllOptionsSelected] =
        React.useState(selectedAll);

    // If all options are selected by default,
    // make sure groupSelection object contains keys of group names set to true
    React.useEffect(() => {
        let groupSelection = {};
        if (allSelectedByDefault) {
            options.forEach((option) => {
                if (option?.name) {
                    groupSelection[option?.name] = true;
                }
            });
            setSelectedGroups(groupSelection);
        }
    }, [options]);

    React.useEffect(() => {
        setAllOptionsSelected(selectedAll);
    }, [value]);

    const isSelectionTypeMultiple = multipleSelection ?? Array.isArray(value);

    const listBoxProps = {
        selectedOptions: value,
        set_values: set_values,
        allOptionsWithoutAllOption,
        allOptionsSelected,
        allowedItemsSelection,
        multipleSelection: isSelectionTypeMultiple,
        var_values,
        var_values_nested_selected_options_key,
        groupOptionsFormat: groupByOptions && true,
        listMode,
        setListMode,
        selectedGroups,
        setSelectedGroups,
        groupSelectionPostCallback,
    };

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    // Post event change action
    const onChangeEventAction = function () {
        if (typeof onChange === 'function') {
            onChange.apply(null, arguments);
        }
        // Set text field to blurred after applying selection changes
        setTextFieldBlurred(true);
    };

    const onCloseEventAction = function () {
        if (typeof onClose === 'function') {
            onClose.apply(null, arguments);
            setSearchText('');
            setTextFieldBlurred(true);
        }
    };

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <Autocomplete
            disableListWrap
            disableClearable={disableClearIcon}
            disableCloseOnSelect={isSelectionTypeMultiple ? true : false}
            multiple={isSelectionTypeMultiple ? true : false}
            options={allOptions}
            value={value}
            isOptionEqualToValue={(option, optionValue) =>
                option &&
                optionValue &&
                option?.value &&
                optionValue?.value &&
                option.value === optionValue.value
            }
            getOptionDisabled={(option) =>
                listMode === 'default' &&
                allOptionsSelected &&
                option.value !== 'all'
                    ? true
                    : false
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={'Locations'}
                    onClick={() => setTextFieldBlurred(false)}
                    onBlur={() => {
                        setTextFieldBlurred(true);
                        // Return to default mode if the user focuses out of selection search field
                        if (setListMode) setListMode('default');
                    }}
                />
            )}
            inputValue={searchText}
            onInputChange={(event, inputFieldValue, reason) =>
                reason !== 'reset' && setSearchText(inputFieldValue)
            }
            onKeyUpCapture={(event) =>
                setListMode &&
                setListMode(event.target.value !== '' ? 'search' : 'default')
            }
            onKeyDownCapture={(event) =>
                event.key === 'Backspace' && event.stopPropagation()
            }
            //isSelectionTypeMultiple
            {...(groupByOptions && { groupBy: (option) => option.group })}
            {...(!isSelectionTypeMultiple && {
                getOptionLabel: (option) => option?.label,
            })}
            //groupBy={(option) => option.group}
            onChange={onChangeEventAction}
            onClose={onCloseEventAction}
            //renderInput={(params) => <TextField {...params} label={fieldLabel} onClick={() => setTextFieldBlurred(false)} onBlur={() => setTextFieldBlurred(true)} />}
            renderOption={(props, option) => [props, option]}
            renderGroup={(params) => params}
            selectOnFocus={true}
            renderTags={() =>
                textFieldBlurred && searchText === '' ? (
                    <Box
                        component="span"
                        sx={{
                            color: 'white',
                            zIndex: 51,
                            position: { xs: 'absolute', sm: 'static' },
                        }}
                    >
                        <Box
                            component="span"
                            sx={{ display: { xs: 'none', sm: 'inline' } }}
                        >
                            Selected{' '}
                        </Box>
                        {isSelectionTypeMultiple
                            ? `${
                                  allOptionsSelected ? 'All' : value.length
                              } ${fieldLabel}`
                            : allOptionsWithoutAllOption}
                    </Box>
                ) : (
                    ''
                )
            }
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            ListboxProps={listBoxProps}
        />
    );
}

LocationsAutocompleteDropdownList.defaultProps = {
    options: [],
    value: [],
    onChange: () => {},
    fieldLabel: 'Locations',
    var_values: [],
    set_values: null,
    lookup_options_format: false,
    var_values_nested_selected_options_key: null,
    multipleSelection: null,
    disableClearIcon: false,
};

LocationsAutocompleteDropdownList.propTypes = {
    options: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        ),
        PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
                label: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
            })
        ),
    ]),
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        ),
        PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
                label: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
            })
        ),
    ]),
    onChange: PropTypes.func,
    fieldLabel: PropTypes.string,
    var_values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    set_values: PropTypes.func,
    lookup_options_format: PropTypes.bool,
    var_values_nested_selected_options_key: PropTypes.string,
    multipleSelection: PropTypes.bool,
    disableClearIcon: PropTypes.bool,
};
