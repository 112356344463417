import React, { useState, useEffect } from 'react';
import {
    Menu,
    MenuItem,
    Avatar,
    Box,
    Divider,
    Button,
    Grid,
    Slide,
    Typography,
} from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { useData } from '../../../Context/Context';
import API from '../../../libs/ServerAPIs';
import Modal from '../../../Components/Modal';
import { useForm, Form } from '../../../Components/UseForm';
import { useSnackbar } from 'notistack';
import Controls from '../../../Components/Controls/Controls';
import ModalConfirm from '../../../Components/ModalConfirm';
import { LoadingButton } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import LastLoggedIn from '../LastLoggedIn';

function UserMenu({
    anchorEl,
    handleMenuOpen,
    handleMenuClose,
    openMdlPreferences,
}) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const history = useNavigate();
    const initValues = {
        currentPassword: '***************',
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        default_branch: '',
    };
    const {
        userData,
        primaryUser,
        auth,
        activeSession,
        selectedBranch,
        showCustomerLocationsDropdown,
        mobileCheck,
        selectedBranchLocations,
        selectedAllLocations,
        userBranchLocations,
    } = useData(useData);
    const [user_details, setUserDetails] = userData;
    const [primary_user] = primaryUser;
    const [, setIsAuth] = auth;
    const [, setIsActiveSession] = activeSession;
    const [, setSelectedBranch] = selectedBranch;
    const [, set_selected_branch_locations] = selectedBranchLocations;
    const [, set_user_branch_locations] = userBranchLocations;
    const [, set_selected_all_locations] = selectedAllLocations;
    const [, set_show_customer_locations_dropdown] =
        showCustomerLocationsDropdown;
    const [var_mdl_changePassword_open, set_mdl_changePassword_open] =
        useState(false);
    const [isMobile] = mobileCheck;
    const [
        var_mdl_reset_password_confirmation_open,
        set_mdl_reset_password_confirmation_open,
    ] = useState(false);
    const [loading, setLoading] = useState(false);
    const [var_password_length, set_password_length] = useState(false);
    const [var_password_lowercase, set_password_lowercase] = useState(false);
    const [var_password_uppercase, set_password_uppercase] = useState(false);
    const [var_password_number, set_password_number] = useState(false);
    const [var_password_special_character, set_password_special_character] =
        useState(false);
    const [var_confirm_password, set_confirm_password] = useState(false);
    const [var_password_ready, set_password_ready] = useState(false);

    const { var_values, var_errors, handleInputChange } = useForm(initValues);

    const { enqueueSnackbar } = useSnackbar();

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        checkPassword();
    }, [var_values.newPassword, var_values.confirmNewPassword]);

    useEffect(() => {
        if (
            var_password_length &&
            var_password_lowercase &&
            var_password_uppercase &&
            var_password_number &&
            var_password_special_character &&
            var_confirm_password
        ) {
            set_password_ready(true);
        } else {
            set_password_ready(false);
        }
    }, [
        var_password_length,
        var_password_lowercase,
        var_password_uppercase,
        var_password_number,
        var_password_special_character,
        var_confirm_password,
    ]);

    const logoutClickHandler = () => {
        setLoading(true);
        API.get('/api/v1/auth/logout').then((res) => {
            if (res.message) {
                setIsAuth(false);
                setIsActiveSession(false);
                setUserDetails(null);
                set_user_branch_locations([]);
                setSelectedBranch(null);
                set_selected_all_locations(false);
                set_selected_branch_locations([]);
                set_show_customer_locations_dropdown(false);
                localStorage.clear();
                if (res.redirectURL) {
                    window.open(res.redirectURL);
                }
                history('/', { replace: true });
            }
        });
    };

    const handlePasswordUpdate = (e) => {
        // if no errors then send update password request
        if (var_password_ready) {
            API.put('/api/v1/user/update-password', {
                confirm_password: var_values.confirmNewPassword,
                old_password: var_values.oldPassword,
                new_password: var_values.newPassword,
            }).then((response) => {
                showSnackbar(
                    response.error ? 'error' : 'success',
                    response.message
                );
                if (!response.error) {
                    //resetForm();
                    closeMdlChangePassword();
                }
            });
        }
    };
    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    function openMdlChangePassword() {
        set_mdl_changePassword_open(true);
    }

    function closeMdlChangePassword() {
        set_mdl_changePassword_open(false);
    }

    function showSnackbar(variant, message) {
        enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            TransitionComponent: Slide,
        });
    }

    const handlePasswordReset = (e) => {
        if (user_details?.isImpersonate) {
            API.put(
                `/api/v1/user-impersonate/reset-password/${user_details?.userID}`,
                {}
            ).then((response) => {
                set_mdl_reset_password_confirmation_open(false);
                showSnackbar(
                    response.error ? 'error' : 'success',
                    response.message
                );
            });
        } else {
            set_mdl_reset_password_confirmation_open(false);
        }
    };

    const closeMdlResetPasswordConfirm = () => {
        set_mdl_reset_password_confirmation_open(false);
    };

    function checkPassword() {
        if (var_values.newPassword) {
            let password = var_values.newPassword;

            if (password.length >= 8) {
                set_password_length(true);
            } else {
                set_password_length(false);
            }

            if (/[a-z]/.test(password)) {
                set_password_lowercase(true);
            } else {
                set_password_lowercase(false);
            }

            if (/[A-Z]/.test(password)) {
                set_password_uppercase(true);
            } else {
                set_password_uppercase(false);
            }

            if (/[0-9]/.test(password)) {
                set_password_number(true);
            } else {
                set_password_number(false);
            }
            // eslint-disable-next-line
            if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g.test(password)) {
                set_password_special_character(true);
            } else {
                set_password_special_character(false);
            }

            if (var_values.newPassword === var_values.confirmNewPassword) {
                set_confirm_password(true);
            } else {
                set_confirm_password(false);
            }
        } else {
            set_password_length(false);
            set_password_lowercase(false);
            set_password_uppercase(false);
            set_password_number(false);
            set_password_special_character(false);
            set_confirm_password(false);
        }
    }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={handleMenuOpen}
                onClose={handleMenuClose}
                sx={{ '& .MuiMenu-list': { p: '0px' } }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    sx={{ py: '12px', minWidth: '210px', alignItems: 'center' }}
                    onClick={() => {
                        openMdlPreferences();
                        handleMenuClose();
                    }}
                >
                    <Avatar
                        sx={{ bgcolor: deepOrange[100] }}
                        alt={
                            user_details?.firstName +
                            ' ' +
                            user_details?.lastName
                        }
                        src={
                            ((user_details?.isImpersonate &&
                                '/api/v1/user-impersonate/get-profile-picture/') ||
                                '/api/v1/user/get-profile-picture?id=') +
                            user_details.userID +
                            `${user_details?.isImpersonate ? '?' : '&'}time=` +
                            new Date(user_details.updatedOn).getTime()
                        }
                    />
                    <Box sx={{ pl: '10px' }}>
                        <Box
                            sx={{
                                fontSize: '15px',
                                fontWeight: '500',
                                textTransform: 'capitalize',
                                lineHeight: '1',
                            }}
                        >
                            {user_details?.firstName} {user_details?.lastName}
                        </Box>
                        <Box sx={{ fontSize: '12px', color: 'text.secondary' }}>
                            {user_details?.role}
                        </Box>
                    </Box>
                </MenuItem>

                <Divider sx={{ mt: '0 !important' }} />
                <MenuItem
                    sx={{ py: '8px' }}
                    onClick={() => {
                        openMdlPreferences();
                        handleMenuClose();
                    }}
                >
                    Preferences
                </MenuItem>
                {user_details?.isImpersonate &&
                    primary_user?.permissions['Manage Users']?.includes(
                        'Edit'
                    ) &&
                    !user_details?.isAdUser && (
                        <MenuItem
                            sx={{ py: '8px' }}
                            onClick={() => {
                                handleMenuClose();
                                set_mdl_reset_password_confirmation_open(true);
                            }}
                        >
                            Reset Password
                        </MenuItem>
                    )}
                {!user_details?.isImpersonate && !user_details?.isAdUser && (
                    <MenuItem
                        sx={{ py: '8px' }}
                        onClick={() => {
                            handleMenuClose();
                            openMdlChangePassword();
                        }}
                    >
                        Change password
                    </MenuItem>
                )}
                {isMobile && (
                    <>
                        <Divider sx={{ mt: '0 !important' }} />
                        <MenuItem sx={{ py: '8px' }}>
                            <Box
                                component="li"
                                sx={{ '& .MuiBox-root': { color: '#455A64' } }}
                            >
                                <LastLoggedIn userMenu={true} />
                            </Box>
                        </MenuItem>
                    </>
                )}
                <MenuItem
                    disableRipple
                    sx={{
                        py: '8px',
                        mb: '8px',
                        '&:hover': { backgroundColor: 'rgba(0,0,0,0)' },
                    }}
                >
                    <LoadingButton
                        loading={loading}
                        disabled={user_details?.isImpersonate || false}
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (user_details?.isImpersonate) {
                                return false;
                            }
                            logoutClickHandler();
                        }}
                    >
                        Log Out
                    </LoadingButton>
                </MenuItem>
            </Menu>
            {/***** MODAL: CHANGE PASSWORD ********************************************************/}
            <Modal
                title="Change password"
                openModal={var_mdl_changePassword_open}
                closeModal={closeMdlChangePassword}
                width="xs"
                dialogActions={
                    <>
                        <Button onClick={closeMdlChangePassword}>Cancel</Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handlePasswordUpdate}
                            disabled={!var_password_ready}
                        >
                            Save
                        </Button>
                    </>
                }
            >
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controls.Input
                                name="oldPassword"
                                label="Old password"
                                value={var_values.oldPassword}
                                error={var_errors.oldPassword}
                                onChange={handleInputChange}
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controls.Input
                                name="newPassword"
                                label="New password"
                                value={var_values.newPassword}
                                error={var_errors.newPassword}
                                onChange={handleInputChange}
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controls.Input
                                name="confirmNewPassword"
                                label="Confirm new password"
                                value={var_values.confirmNewPassword}
                                error={var_errors.confirmNewPassword}
                                onChange={handleInputChange}
                                type="password"
                            />
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            padding: '16px',
                            backgroundColor: '#EAEEF3',
                            borderRadius: '4px',
                            mt: '16px',
                        }}
                    >
                        <Typography variant="h5" sx={{ mb: '12px' }}>
                            Password must contain the following:
                        </Typography>
                        <Box
                            className="validation-item"
                            sx={{
                                display: 'flex',
                                color: var_password_lowercase
                                    ? 'success.main'
                                    : 'error.main',
                                fontSize: '14px',
                            }}
                        >
                            <Box
                                className="validation-icon"
                                sx={{ mr: '12px' }}
                            >
                                {var_password_lowercase ? (
                                    <CheckIcon sx={{ fontSize: '18px' }} />
                                ) : (
                                    <ClearIcon sx={{ fontSize: '18px' }} />
                                )}
                            </Box>
                            <Box className="validation-message">
                                A lowercase letter
                            </Box>
                        </Box>
                        <Box
                            className="validation-item"
                            sx={{
                                display: 'flex',
                                color: var_password_uppercase
                                    ? 'success.main'
                                    : 'error.main',
                                fontSize: '14px',
                            }}
                        >
                            <Box
                                className="validation-icon"
                                sx={{ mr: '12px' }}
                            >
                                {var_password_uppercase ? (
                                    <CheckIcon sx={{ fontSize: '18px' }} />
                                ) : (
                                    <ClearIcon sx={{ fontSize: '18px' }} />
                                )}
                            </Box>
                            <Box className="validation-message">
                                A capital (uppercase) letter
                            </Box>
                        </Box>
                        <Box
                            className="validation-item"
                            sx={{
                                display: 'flex',
                                color: var_password_number
                                    ? 'success.main'
                                    : 'error.main',
                                fontSize: '14px',
                            }}
                        >
                            <Box
                                className="validation-icon"
                                sx={{ mr: '12px' }}
                            >
                                {var_password_number ? (
                                    <CheckIcon sx={{ fontSize: '18px' }} />
                                ) : (
                                    <ClearIcon sx={{ fontSize: '18px' }} />
                                )}
                            </Box>
                            <Box className="validation-message">A number</Box>
                        </Box>
                        <Box
                            className="validation-item"
                            sx={{
                                display: 'flex',
                                color: var_password_special_character
                                    ? 'success.main'
                                    : 'error.main',
                                fontSize: '14px',
                            }}
                        >
                            <Box
                                className="validation-icon"
                                sx={{ mr: '12px' }}
                            >
                                {var_password_special_character ? (
                                    <CheckIcon sx={{ fontSize: '18px' }} />
                                ) : (
                                    <ClearIcon sx={{ fontSize: '18px' }} />
                                )}
                            </Box>
                            <Box className="validation-message">
                                A special character
                            </Box>
                        </Box>
                        <Box
                            className="validation-item"
                            sx={{
                                display: 'flex',
                                color: var_password_length
                                    ? 'success.main'
                                    : 'error.main',
                                fontSize: '14px',
                            }}
                        >
                            <Box
                                className="validation-icon"
                                sx={{ mr: '12px' }}
                            >
                                {var_password_length ? (
                                    <CheckIcon sx={{ fontSize: '18px' }} />
                                ) : (
                                    <ClearIcon sx={{ fontSize: '18px' }} />
                                )}
                            </Box>
                            <Box className="validation-message">
                                Minimum 8 characters
                            </Box>
                        </Box>

                        <Box
                            className="validation-item"
                            sx={{
                                display: 'flex',
                                color: var_confirm_password
                                    ? 'success.main'
                                    : 'error.main',
                                fontSize: '14px',
                            }}
                        >
                            <Box
                                className="validation-icon"
                                sx={{ mr: '12px' }}
                            >
                                {var_confirm_password ? (
                                    <CheckIcon sx={{ fontSize: '18px' }} />
                                ) : (
                                    <ClearIcon sx={{ fontSize: '18px' }} />
                                )}
                            </Box>
                            <Box className="validation-message">
                                Match password
                            </Box>
                        </Box>
                    </Box>
                </Form>
            </Modal>

            {/***** END MODAL: CHANGE PASSWORD ****************************************************/}

            {/***** MODAL: RESET PASSWORD CONFIRMATION *********************************************************/}
            <ModalConfirm
                title={`Are you sure you want to send Reset Password Link to "${user_details?.email}" email?`}
                text={''}
                openModal={var_mdl_reset_password_confirmation_open}
                closeModal={closeMdlResetPasswordConfirm}
                type="error"
                handleConfirm={handlePasswordReset}
            />

            {/***** END MODAL: RESET PASSWORD CONFIRMATION  ****************************************************/}
        </>
    );
}

export default UserMenu;
