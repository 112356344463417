import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import SettingsIcon from '@mui/icons-material/Settings';
import { useData } from '../../Context/Context';
import {
    StyledList,
    StyledLink,
    StyledIcon,
    StyledText,
    StyledListItem,
} from './styles';

function DrawerList() {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const { userData } = useData(useData);
    const [user_details] = userData;

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {}, []);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <>
            <StyledList component="nav">
                {user_details !== undefined &&
                    user_details?.permissions['Dashboard']?.includes(
                        'View'
                    ) && (
                        <StyledListItem>
                            <StyledLink
                                component={NavLink}
                                // activeClassName="Mui-selected"
                                to="/app"
                                end
                            >
                                <StyledIcon>
                                    <DashboardIcon />
                                </StyledIcon>
                                <StyledText disableTypography>
                                    Dashboard
                                </StyledText>
                            </StyledLink>
                        </StyledListItem>
                    )}
                {user_details !== undefined &&
                    user_details?.permissions['Locations']?.includes(
                        'View'
                    ) && (
                        <StyledListItem>
                            <StyledLink
                                component={NavLink}
                                // activeClassName="Mui-selected"
                                to="/app/locations"
                                end
                            >
                                <StyledIcon>
                                    <MyLocationIcon />
                                </StyledIcon>
                                <StyledText disableTypography>
                                    Locations
                                </StyledText>
                            </StyledLink>
                        </StyledListItem>
                    )}
                {user_details !== undefined &&
                    user_details?.permissions['My Services']?.includes(
                        'View'
                    ) && (
                        <StyledListItem>
                            <StyledLink
                                component={NavLink}
                                // activeClassName="Mui-selected"
                                to="/app/services"
                                end
                            >
                                <StyledIcon>
                                    <MiscellaneousServicesIcon />
                                </StyledIcon>
                                <StyledText disableTypography>
                                    Services
                                </StyledText>
                            </StyledLink>
                        </StyledListItem>
                    )}
                {user_details !== undefined &&
                    user_details?.permissions['Manage Users']?.includes(
                        'View'
                    ) && (
                        <StyledListItem>
                            <StyledLink
                                component={NavLink}
                                // activeClassName="Mui-selected"
                                to="/app/manage-users"
                                end
                            >
                                <StyledIcon>
                                    <PeopleOutlineIcon />
                                </StyledIcon>
                                <StyledText disableTypography>
                                    Manage Users
                                </StyledText>
                            </StyledLink>
                        </StyledListItem>
                    )}
                {user_details !== undefined &&
                    user_details?.permissions['Access Management']?.includes(
                        'View'
                    ) && (
                        <StyledListItem>
                            <StyledLink
                                component={NavLink}
                                // activeClassName="Mui-selected"
                                to="/app/access-requests"
                                end
                            >
                                <StyledIcon>
                                    <AccessibilityIcon />
                                </StyledIcon>
                                <StyledText disableTypography>
                                    Access Management
                                </StyledText>
                            </StyledLink>
                        </StyledListItem>
                    )}
                {user_details !== undefined &&
                    user_details?.permissions['Conditions']?.includes(
                        'View'
                    ) && (
                        <StyledListItem>
                            <StyledLink
                                component={NavLink}
                                // activeClassName="Mui-selected"
                                to="/app/conditions"
                                end
                            >
                                <StyledIcon>
                                    <ContentPasteIcon />
                                </StyledIcon>
                                <StyledText disableTypography>
                                    Conditions
                                </StyledText>
                            </StyledLink>
                        </StyledListItem>
                    )}
                {user_details !== undefined &&
                    user_details?.permissions['Billing']?.includes('View') && (
                        <StyledListItem>
                            <StyledLink
                                component={NavLink}
                                // activeClassName="Mui-selected"
                                to="/app/billing"
                                end
                            >
                                <StyledIcon>
                                    <ReceiptIcon />
                                </StyledIcon>
                                <StyledText disableTypography>
                                    Billing
                                </StyledText>
                            </StyledLink>
                        </StyledListItem>
                    )}
                {user_details !== undefined &&
                    user_details?.permissions['Documents']?.includes(
                        'View'
                    ) && (
                        <StyledListItem>
                            <StyledLink
                                component={NavLink}
                                // activeClassName="Mui-selected"
                                to="/app/documents"
                                end
                            >
                                <StyledIcon>
                                    <DescriptionIcon />
                                </StyledIcon>
                                <StyledText disableTypography>
                                    Documents
                                </StyledText>
                            </StyledLink>
                        </StyledListItem>
                    )}
                {user_details !== undefined &&
                    user_details?.permissions['Reports']?.includes('View') && (
                        <StyledListItem>
                            <StyledLink
                                component={NavLink}
                                // activeClassName="Mui-selected"
                                to="/app/reports"
                                end
                            >
                                <StyledIcon>
                                    <AssessmentIcon />
                                </StyledIcon>
                                <StyledText disableTypography>
                                    Reports
                                </StyledText>
                            </StyledLink>
                        </StyledListItem>
                    )}
                {user_details !== undefined &&
                    user_details?.permissions['User Settings']?.includes(
                        'View'
                    ) && (
                        <StyledListItem>
                            <StyledLink
                                component={NavLink}
                                // activeClassName="Mui-selected"
                                to="/app/settings"
                                end
                            >
                                <StyledIcon>
                                    <SettingsIcon />
                                </StyledIcon>
                                <StyledText disableTypography>
                                    Settings
                                </StyledText>
                            </StyledLink>
                        </StyledListItem>
                    )}
                {user_details !== undefined &&
                    user_details?.permissions['Help']?.includes('View') && (
                        <StyledListItem>
                            <StyledLink
                                component={NavLink}
                                // activeClassName="Mui-selected"
                                to="/app/help"
                                end
                            >
                                <StyledIcon>
                                    <HelpOutlineIcon />
                                </StyledIcon>
                                <StyledText disableTypography>Help</StyledText>
                            </StyledLink>
                        </StyledListItem>
                    )}
            </StyledList>
        </>
    );
}

export default DrawerList;
