import React, { useEffect, useState } from 'react';
import Controls from '../../../Components/Controls/Controls';
import { useForm } from '../../../Components/UseForm';
import { useData } from '../../../Context/Context';
import { useSnackbar } from 'notistack';
import API from '../../../libs/ServerAPIs';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Slide,
} from '@mui/material';

function TabDashboard({ var_tabValue, closeModal }) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const { userData, userBranches, selectedBranch } = useData(useData);
    const [user_branches] = userBranches;
    const [, setSelectedBranch] = selectedBranch;
    const history = useNavigate();
    const [user_details, setUserDetails] = userData;
    const [var_save_default_branch_loading, set_save_default_branch_loading] =
        useState(false);
    const [widgetList, setWidgetList] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const initValues = {
        widgets: {},
        default_branch: user_details.defaultBranch,
    };
    const { var_values, set_values, handleInputChange } = useForm(initValues);

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        if (var_tabValue === 'dashboard') {
            if (
                user_details &&
                user_details.permissions &&
                user_details.permissions['Widgets']
            ) {
                setWidgetList(user_details.permissions['Widgets']);
                let userWidgets = {};
                user_details.permissions['Widgets'].map((widget) => {
                    const widgetName = widget.toLowerCase().replace(/ /g, '_');
                    userWidgets[widgetName] =
                        (user_details &&
                            user_details.widgets &&
                            user_details.widgets.includes(widgetName)) ||
                        false;
                    return userWidgets;
                });
                set_values({
                    widgets: userWidgets,
                    default_branch:
                        (user_details && user_details.defaultBranch) ||
                        (user_branches.length && user_branches[0].value),
                });
            }
        }
    }, [var_tabValue]);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    function showSnackbar(variant, message) {
        enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            TransitionComponent: Slide,
        });
    }

    function updateWidgets() {
        const keys = Object.keys(var_values.widgets);
        const widgets = keys.filter((k) => var_values.widgets[k] === true);
        API.put('/api/v1/user/update-preferences', { widgets }).then(
            (update_response) => {
                if (!update_response.error) {
                    API.get('/api/v1/auth').then((userInfo) => {
                        if (!userInfo.error) {
                            setUserDetails(userInfo);
                            closeModal();
                            history('/app', { replace: true });
                        }
                    });
                }
                showSnackbar(
                    update_response.error ? 'error' : 'success',
                    update_response.message
                );
            }
        );
    }

    function handleSaveDefaultBranch() {
        set_save_default_branch_loading(true);
        API.put('/api/v1/user/update-preferences', {
            default_branch: Number.parseInt(var_values.default_branch),
        }).then((update_response) => {
            set_save_default_branch_loading(false);
            if (!update_response.error) {
                API.get('/api/v1/auth').then((userInfo) => {
                    setUserDetails(userInfo);
                    setSelectedBranch(var_values.default_branch);
                    closeModal();
                });
            }
            showSnackbar(
                update_response.error ? 'error' : 'success',
                update_response.message
            );
        });
    }

    function handleWidgetChange(e) {
        const { name, value } = e.target;
        set_values({
            ...var_values,
            widgets: {
                ...var_values.widgets,
                [name]: value,
            },
        });
    }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        title="Widgets"
                        subheader="Choose the default widgets for dashboard."
                    />

                    <Divider />

                    <CardContent sx={{ pt: '24px' }}>
                        <Grid container spacing={1}>
                            {widgetList.map((widget, index) => {
                                const widgetName = widget
                                    .toLowerCase()
                                    .replace(/ /g, '_');
                                return (
                                    <Grid item xs={12} key={`widget-${index}`}>
                                        <Controls.Checkbox
                                            name={widgetName}
                                            label={widget}
                                            value={
                                                var_values.widgets[widgetName]
                                            }
                                            onChange={handleWidgetChange}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </CardContent>

                    <Divider />

                    <CardActions
                        sx={{ px: '12px', justifyContent: 'flex-end' }}
                    >
                        <Button
                            onClick={() => updateWidgets()}
                            variant="contained"
                            color="secondary"
                        >
                            Update
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12}>
                {user_branches.length > 0 && (
                    <Card>
                        <CardHeader
                            title="Branch settings"
                            subheader="Choose the default branch."
                        />

                        <Divider />

                        <CardContent sx={{ p: '24px' }}>
                            <Controls.Select
                                name="default_branch"
                                label="Default Branch"
                                value={var_values.default_branch}
                                onChange={handleInputChange}
                                options={user_branches}
                            />
                        </CardContent>

                        <Divider />

                        <CardActions
                            sx={{ px: '12px', justifyContent: 'flex-end' }}
                        >
                            <LoadingButton
                                onClick={handleSaveDefaultBranch}
                                variant="contained"
                                color="secondary"
                                loading={var_save_default_branch_loading}
                                disabled={var_save_default_branch_loading}
                            >
                                Save
                            </LoadingButton>
                        </CardActions>
                    </Card>
                )}
            </Grid>
        </Grid>
    );
}

export default TabDashboard;
