import { useState, createContext, forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import InfiniteLoader from 'react-window-infinite-loader';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import {
    Autocomplete,
    autocompleteClasses,
    Box,
    Checkbox,
    ListSubheader,
    ListItem,
    ListItemText,
    Popper,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';

const LISTBOX_PADDING = 8; // px
const LOADING = 1;
const LOADED = 2;
let itemStatusMap = {};
const isItemLoaded = (index) => {
    return !!itemStatusMap[index];
};
const loadMoreItems = (startIndex, stopIndex) => {
    // Set the status of items to LOADING which are not fetched
    for (let index = startIndex; index <= stopIndex; index++) {
        itemStatusMap[index] = LOADING;
    }

    return new Promise((resolve) =>
        setTimeout(() => {
            // Once fetched, update the status to LOADED
            for (let index = startIndex; index <= stopIndex; index++) {
                itemStatusMap[index] = LOADED;
            }
            resolve();
        }, 100)
    );
};

const OuterElementContext = createContext({});
const OuterElementType = forwardRef((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
    //ListboxComponent.propTypes = { children: PropTypes.node };
    ListboxComponent.propTypes = {
        multipleSelection: PropTypes.bool,
        children: PropTypes.node,
        selectedOptions: PropTypes.array,
        var_values: PropTypes.array,
        set_values: PropTypes.func,
        var_values_nested_selected_options_key: PropTypes.string,
        selectedGroups: PropTypes.object,
        setSelectedGroups: PropTypes.func,
    };

    let {
        children,
        selectedOptions,
        var_values,
        set_values,
        var_values_nested_selected_options_key,
        multipleSelection,
        selectedGroups,
        setSelectedGroups,
        groupSelectionPostCallback,
        ...other
    } = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });

    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }
        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={itemCount}
                    loadMoreItems={loadMoreItems}
                >
                    {({ onItemsRendered, ref }) => (
                        <VariableSizeList
                            itemData={itemData}
                            height={getHeight() + 2 * LISTBOX_PADDING}
                            width="100%"
                            ref={ref}
                            outerElementType={OuterElementType}
                            innerElementType="ul"
                            itemSize={(index) => getChildSize(itemData[index])}
                            overscanCount={1}
                            itemCount={itemCount}
                            onItemsRendered={onItemsRendered}
                        >
                            {(props) => {
                                const { data, index, style } = props;
                                const dataSet = data[index];
                                const inlineStyle = {
                                    ...style,
                                    top: style.top + LISTBOX_PADDING,
                                };

                                return itemStatusMap[index] === LOADED ? (
                                    dataSet.hasOwnProperty('group') ? (
                                        <ListSubheader
                                            {...dataSet[0]}
                                            component="div"
                                            key={index}
                                            style={{
                                                ...inlineStyle,
                                                ...(multipleSelection &&
                                                    dataSet.group.toLowerCase() !==
                                                        'ungrouped' && {
                                                        cursor: 'pointer',
                                                    }),
                                            }}
                                            {...(multipleSelection &&
                                                dataSet.group.toLowerCase() !==
                                                    'ungrouped' && {
                                                    onClick: () => {
                                                        // Group is selected, unselect all option those options
                                                        if (
                                                            dataSet.group in
                                                                selectedGroups &&
                                                            selectedGroups[
                                                                dataSet.group
                                                            ] === true
                                                        ) {
                                                            selectedGroups[
                                                                dataSet.group
                                                            ] = false;
                                                            selectedOptions =
                                                                selectedOptions.filter(
                                                                    (
                                                                        selectedOption
                                                                    ) =>
                                                                        selectedOption.group !==
                                                                        dataSet.group
                                                                );
                                                        }
                                                        // Otherwise either entire group is not selected or partially selected, select all options in group
                                                        else {
                                                            selectedGroups[
                                                                dataSet.group
                                                            ] = true;
                                                            selectedOptions = [
                                                                ...selectedOptions.filter(
                                                                    (
                                                                        selectedOption
                                                                    ) =>
                                                                        selectedOption.group !==
                                                                        dataSet.group
                                                                ),
                                                                ...dataSet.children.map(
                                                                    (
                                                                        childElement
                                                                    ) =>
                                                                        childElement[1]
                                                                ),
                                                            ];
                                                        }
                                                        // Check if selected options are being managed in key of var_values otherwise merge options in var_values directly
                                                        //set_values(var_values_nested_selected_options_key !== null ? ({ ...var_values, [var_values_nested_selected_options_key]: selectedOptions }) : ({ selectedOptions }));
                                                        set_values(
                                                            var_values_nested_selected_options_key !==
                                                                null
                                                                ? {
                                                                      ...var_values,
                                                                      [var_values_nested_selected_options_key]:
                                                                          selectedOptions,
                                                                  }
                                                                : [
                                                                      ...selectedOptions,
                                                                  ]
                                                        );
                                                    },
                                                })}
                                        >
                                            {dataSet.group}
                                        </ListSubheader>
                                    ) : (
                                        <ListItem
                                            key={index}
                                            {...dataSet[0]}
                                            component="div"
                                            style={inlineStyle}
                                        >
                                            {multipleSelection && (
                                                <Checkbox
                                                    checked={
                                                        selectedOptions &&
                                                        selectedOptions.findIndex(
                                                            (selectedOption) =>
                                                                selectedOption.value ===
                                                                dataSet[1].value
                                                        ) > -1
                                                    }
                                                />
                                            )}
                                            <ListItemText
                                                primary={dataSet[1].label}
                                                primaryTypographyProps={{
                                                    style: {
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    },
                                                }}
                                                secondary={dataSet[1].address}
                                                secondaryTypographyProps={{
                                                    style: {
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    },
                                                }}
                                            />
                                        </ListItem>
                                    )
                                ) : (
                                    <ListItem
                                        key={index}
                                        {...dataSet[0]}
                                        component="div"
                                        style={inlineStyle}
                                    >
                                        <Typography>...Loading</Typography>
                                    </ListItem>
                                );
                            }}
                        </VariableSizeList>
                    )}
                </InfiniteLoader>
            </OuterElementContext.Provider>
        </div>
    );
});

const StyledPopper = styled(Popper)({
    minWidth: '180px',
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

const mapFromLookupToComponentFormat = (options) =>
    options
        .map((group) =>
            group.locations.map((location) => ({
                group: group.name,
                value: location.value,
                label: location.label,
                address: location.address,
                branch: location.branch,
            }))
        )
        .flat();

export default function CustomAutocompleteGrouping({
    options,
    value,
    onChange,
    fieldLabel = 'Locations',
    var_values = [],
    set_values = null,
    lookup_options_format = false,
    var_values_nested_selected_options_key = null,
    multipleSelection = null,
    disableClearIcon = false,
    disabled = false,
}) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const [textFieldBlurred, setTextFieldBlurred] = useState(true);
    const [selectedGroups, setSelectedGroups] = useState({});
    const [searchText, setSearchText] = useState('');

    const isSelectionTypeMultiple = multipleSelection ?? Array.isArray(value);

    const listBoxProps = {
        selectedOptions: value,
        set_values: set_values,
        multipleSelection: isSelectionTypeMultiple,
        var_values,
        var_values_nested_selected_options_key,
        selectedGroups,
        setSelectedGroups,
    };

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    const onChangeEventAction = function () {
        if (typeof onChange === 'function') {
            onChange.apply(null, arguments);
        }
        setTextFieldBlurred(true);
    };

    const onCloseEventAction = function () {
        setSearchText('');
        setTextFieldBlurred(true);
    };

    /* RENDER APP
    -------------------------------------------------------------------------------------*/

    return (
        <Autocomplete
            disableListWrap
            disableClearable={disableClearIcon}
            disableCloseOnSelect={isSelectionTypeMultiple ? true : false}
            multiple={isSelectionTypeMultiple ? true : false}
            options={
                lookup_options_format
                    ? mapFromLookupToComponentFormat(options)
                    : options
            }
            value={value}
            isOptionEqualToValue={(option, optionValue) =>
                option &&
                optionValue &&
                option?.value &&
                optionValue?.value &&
                option.value === optionValue.value
            }
            //isSelectionTypeMultiple
            {...(lookup_options_format && {
                groupBy: (option) => option.group,
            })}
            onChange={onChangeEventAction}
            onClose={onCloseEventAction}
            onKeyDownCapture={(event) =>
                event.key === 'Backspace' && event.stopPropagation()
            }
            {...(isSelectionTypeMultiple && { inputValue: searchText })}
            onInputChange={(event, inputFieldValue, reason) =>
                reason !== 'reset' && setSearchText(inputFieldValue)
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={fieldLabel}
                    onClick={() => setTextFieldBlurred(false)}
                    onBlur={() => setTextFieldBlurred(true)}
                />
            )}
            renderOption={(props, option) => [props, option]}
            renderGroup={(params) => params}
            selectOnFocus={true}
            renderTags={(tagValues) =>
                textFieldBlurred && searchText === '' ? (
                    <Box component="span">
                        {isSelectionTypeMultiple
                            ? `Selected ${tagValues.length} ${fieldLabel}`
                            : tagValues}
                    </Box>
                ) : (
                    <Box>{isSelectionTypeMultiple ? '' : tagValues}</Box>
                )
            }
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            ListboxProps={listBoxProps}
            disabled={disabled}
        />
    );
}

CustomAutocompleteGrouping.defaultProps = {
    options: [],
    value: [],
    onChange: () => {},
    fieldLabel: 'Locations',
    var_values: [],
    set_values: null,
    lookup_options_format: false,
    var_values_nested_selected_options_key: null,
    multipleSelection: null,
    disableClearIcon: false,
};

CustomAutocompleteGrouping.propTypes = {
    options: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        ),
        PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
                label: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
            })
        ),
    ]),
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        ),
        PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
                label: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
            })
        ),
    ]),
    onChange: PropTypes.func,
    fieldLabel: PropTypes.string,
    var_values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    set_values: PropTypes.func,
    lookup_options_format: PropTypes.bool,
    var_values_nested_selected_options_key: PropTypes.string,
    multipleSelection: PropTypes.bool,
    disableClearIcon: PropTypes.bool,
};
