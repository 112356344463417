const appSettings = {
    development: {
        enableNotifications: true,
        logbookURL: 'http://localhost:5001',
    },
    production: {
        enableNotifications: true,
        logbookURL: '',
        isNginx: true,
    },
};

export default appSettings[process.env.NODE_ENV || 'development'];
