import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PublicRoute } from '../Components/PublicRoute/PublicRoute';

const Login = lazy(() => import('../Pages/Login/login'));
const SignUp = lazy(() => import('../Pages/Signup/signup'));
const RecoverPassword = lazy(
    () => import('../Pages/RecoverPassword/RecoverPassword')
);
const ResetPassword = lazy(
    () => import('../Pages/ResetPassword/ResetPassword')
);
const Dashboard = lazy(() => import('../Pages/Dashboard/dashboard'));
const Locations = lazy(() => import('../Pages/Locations/locations'));
const Services = lazy(() => import('../Pages/Services/services'));
const ManageUsers = lazy(() => import('../Pages/ManageUsers/manage-users'));
const Conditions = lazy(() => import('../Pages/Conditions/conditions'));
const Billing = lazy(() => import('../Pages/Billing/billing'));
const Documents = lazy(() => import('../Pages/Documents/documents'));
const RequestAccess = lazy(
    () => import('../Pages/RequestAccess/request-access')
);
const Help = lazy(() => import('../Pages/Help/help'));
const AccessManagement = lazy(
    () => import('../Pages/AccessManagement/access-management')
);
const Settings = lazy(() => import('../Pages/Settings/settings'));
const Reports = lazy(() => import('../Pages/Reports/reports'));
const NotFound = lazy(() => import('../Pages/NotFound/not-found'));
const StrongPassword = lazy(
    () => import('../Pages/StrongPassword/StrongPassword')
);

export function RenderRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/recover-password" element={<RecoverPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path={'app'} element={<PublicRoute />}>
                <Route
                    path={''}
                    end
                    key={'Dashboard'}
                    element={<Dashboard />}
                />
                <Route
                    path={'locations'}
                    end
                    key={'LocationManagement'}
                    element={<Locations />}
                />
                <Route
                    path={'services'}
                    end
                    key={'Services'}
                    element={<Services />}
                />
                <Route
                    path={'manage-users'}
                    end
                    key={'Manage Users'}
                    element={<ManageUsers />}
                />
                <Route
                    path={'access-requests'}
                    end
                    key={'Access Management'}
                    element={<AccessManagement />}
                />
                <Route
                    path={'conditions'}
                    end
                    key={'Conditions'}
                    element={<Conditions />}
                />
                <Route
                    path={'billing'}
                    end
                    key={'Billing'}
                    element={<Billing />}
                />
                <Route
                    path={'documents'}
                    end
                    key={'Documents'}
                    element={<Documents />}
                />
                <Route
                    path={'request-access'}
                    end
                    key={'AccessManagement'}
                    element={<RequestAccess />}
                />
                <Route path={'help'} end key={'Help'} element={<Help />} />
                <Route
                    path={'settings'}
                    end
                    key={'Settings'}
                    element={<Settings />}
                />
                <Route
                    path={'strong-password'}
                    end
                    key={'Profile Management'}
                    element={<StrongPassword />}
                />
                <Route
                    path={'reports'}
                    end
                    key={'ReportsManagement'}
                    element={<Reports />}
                />
                <Route path={'*'} element={<NotFound />} />
            </Route>
            <Route path={'*'} element={<NotFound />} />
        </Routes>
    );
}
