import React, { useEffect } from 'react';
import { Drawer, Box } from '@mui/material';
import DrawerList from './DrawerList';

function AppDrawer({ container, drawerWidth, open, drawerToggle }) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {}, []);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* RENDER APP
    -------------------------------------------------------------------------------------*/

    return (
        <>
            <Box
                component="nav"
                sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={open}
                    onClose={drawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            backgroundColor: 'primary.dark',
                            top: { xs: '56px', sm: '64px' },
                            border: 'none',
                            height: {
                                xs: 'calc(100% - 56px)',
                                sm: 'calc(100% - 64px)',
                            },
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: '40px',
                            left: '45px',
                            opacity: '0.075',
                            transform: 'rotate(30deg)',
                            textAlign: 'center',
                            '& img': { maxWidth: '140px' },
                        }}
                    >
                        <img src="/images/fly.png" alt="icon fly" />
                    </Box>

                    <DrawerList />
                </Drawer>

                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', lg: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            backgroundColor: 'primary.dark',
                            color: '#fff',
                            border: 'none',
                            top: { xs: '56px', sm: '64px' },
                            height: {
                                xs: 'calc(100% - 56px)',
                                sm: 'calc(100% - 64px)',
                            },
                        },
                    }}
                    open
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: '40px',
                            left: '45px',
                            opacity: '0.075',
                            transform: 'rotate(30deg)',
                            textAlign: 'center',
                            '& img': { maxWidth: '140px' },
                        }}
                    >
                        <img src="/images/fly.png" alt="icon fly" />
                    </Box>

                    <DrawerList />
                </Drawer>
            </Box>
        </>
    );
}

export default AppDrawer;
