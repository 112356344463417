import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useSnackbar } from 'notistack';

import {
    Box,
    AppBar,
    Toolbar,
    IconButton,
    Badge,
    Button,
    Tooltip,
} from '@mui/material';
import UserMenu from './UserMenu';
import MobileMenu from './MobileMenu';
import Logo from '../../logo.png';
import LastLoggedIn from './LastLoggedIn';
import NotificationMenu from './NotificationMenu';
import API from '../../libs/ServerAPIs';
import {
    getRecentActivityList,
    markActivitiesAsRead,
} from '../../libs/activityLog';
import { useData } from '../../Context/Context';
// import CustomAutocomplete from '../CustomAutocomplete';
import LocationsCustomAutocomplete from '../LocationsCustomAutocomplete';
import CustomAutocompleteGrouping from '../CustomAutocompleteGrouping';
import LocationsAutocompleteDropdownList from '../LocationsAutocompleteDropdownList';
import appSettings from '../../config';
import ModalSyncLocationsData from './ModalSyncLocationsData';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function AppTopBar({
    drawerToggle,
    openMdlNotifications,
    closeMdlNotifications,
    openMdlPreferences,
}) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const maxAllowedSelectableLocations = 100;
    const {
        auth,
        activeSession,
        userData,
        userBranches,
        userBranchLocations,
        selectedBranch,
        selectedBranchLocations,
        selectedAllLocations,
        showCustomerLocationsDropdown,
        appTopBarTitleAsPerPage,
        showAppTopBarBranchField,
        showAppTopBarLocationField,
        showServiceBinderLink,
        showLogbookLink,
        userBranchDisabled,
    } = useData(useData);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [user_details] = userData;
    const [isActiveSession] = activeSession;
    const [user_branches] = userBranches;
    const [user_branch_locations] = userBranchLocations;
    const [isUserBranchDisabled] = userBranchDisabled;
    const [var_mdl_open_sync, set_mdl_open_sync] = useState(false);
    const [selected_branch, setSelectedBranch] = selectedBranch;
    const [selected_branch_locations, set_selected_branch_locations] =
        selectedBranchLocations; // Selected locations provided in context
    const [, set_selected_all_locations] = selectedAllLocations;
    // Begin: LocationsCustomAutocomplete states
    const [locations_options, set_locations_options] = useState(
        user_branch_locations
    ); // Autocomplete options list
    const [selected_locations_options, set_selected_locations_options] =
        useState(selected_branch_locations); // Autocomplete selected options value
    /* 
    LocationsCustomAutocomplete List Modes

    Default Mode:
    Normal operating mode of component

    Search Mode:
    Component state is switched to this mode when user is searching
    some location. For that duration, the resulting items in the list
    will appear enabled which are otherwise disabled (in default mode)
    when "All" option is selected. The component will automatically switch
    to default mode once search activity is finished or user has performed
    selection(s)
  */
    const [location_dropdown_list_mode, set_location_dropdown_list_mode] =
        useState('default');
    // End: LocationsCustomAutocomplete states

    // Begin: Customer dropdown states
    const [
        show_customer_locations_dropdown,
        set_show_customer_locations_dropdown,
    ] = showCustomerLocationsDropdown;
    const [show_grouped_locations_options] = useState(
        user_details.role_type === `Customer Admin` ? true : false
    ); // flag variable which decides how to display options in custom groups dropdown list
    const [show_sync_locations_icon] = useState(
        user_details.role_type === `Customer Admin` ? true : false
    );
    // End: Customer dropdown states

    const [app_top_bar_title, set_app_top_bar_title] = appTopBarTitleAsPerPage;
    const [show_branch_dropdown, set_show_branch_dropdown] =
        showAppTopBarBranchField;
    const [show_location_dropdown, set_show_location_dropdown] =
        showAppTopBarLocationField;
    const [show_service_binder_link, set_show_service_binder_link] =
        showServiceBinderLink;
    const [show_logbook_account_link, set_show_logbook_account_link] =
        showLogbookLink;
    const [isAuth] = auth;
    const [var_user_menu_elem, set_user_menu_elem] = useState(null);
    const isMenuOpen = Boolean(var_user_menu_elem);
    const [var_mobile_moreEl, set_mobile_moreEl] = useState(null);
    const isMobileMenuOpen = Boolean(var_mobile_moreEl);
    const [notifications, setNotifications] = useState([]);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [prevNotificationsCount, setPrevNotificationsCount] = useState(0);
    const [totalNotificationsCount, setTotalNotificationsCount] = useState(0);
    const initialTotal = useRef(0);
    const [var_notification_el, set_notification_el] = useState(null);
    const isNotificationsOpen = Boolean(var_notification_el);
    const location = useLocation();
    const history = useNavigate();
    const previousUserBranchLocations = usePrevious(user_branch_locations);
    const component = AppTopBar.name;

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        let fetchInterval;
        let fetchNotifications;
        if (isAuth) {
            // If the user has admin / higher level access query new notifications from server periodically
            // as well as from ongoing activities
            if (
                user_details.role_type === 'Customer Admin' ||
                user_details.role_type === 'Super Admin' ||
                user_details.role_type === 'Content Manager' ||
                user_details.role_type === 'Admin'
            ) {
                fetchNotifications =
                    user_details?.type !== 'demo'
                        ? fetchNewNotifications
                        : generateDemoNotifications;

                // fetchNewNotifications from backend
                if (
                    isActiveSession &&
                    appSettings.enableNotifications === true
                ) {
                    //
                    fetchInterval = setInterval(
                        fetchNotifications,
                        1000 * 60 * 15
                    ); // Fetch new notifications after 20000 milliseconds (20 seconds)
                } else {
                    if (fetchInterval) {
                        clearInterval(fetchInterval);
                    }
                }
            }
        }

        return () => {
            if (fetchInterval) {
                clearInterval(fetchInterval);
            }
            if (fetchNotifications?.name) {
                API.PendingRequestsManager.abortRequest(
                    component,
                    fetchNotifications?.name
                );
            }
        };
    }, [isActiveSession]);

    useEffect(() => {
        if (isActiveSession && appSettings.enableNotifications === true) {
            const recentNotifications = getRecentActivityList();
            setNotifications(recentNotifications);
            setNotificationsCount(recentNotifications.length);
        }
    }, [sessionStorage.activityCount]);

    useEffect(() => {
        // Reset all locations on locations change
        if (locations_options.length > 0) {
            //set_selected_branch_locations(locations_options);
            set_selected_locations_options(locations_options);
        }
        if (
            user_details.role_type === 'Customer Admin' ||
            user_details.role_type === 'Customer'
        ) {
            set_show_customer_locations_dropdown(
                [
                    '/app',
                    '/app/locations',
                    '/app/billing',
                    '/app/services',
                    '/app/reports',
                    //'/app/manage-users',
                    '/app/conditions',
                    '/app/report',
                ].includes(location.pathname)
            );

            set_show_branch_dropdown(false);
            set_show_location_dropdown(false);
        } else {
            set_show_branch_dropdown(
                [
                    '/app',
                    '/app/billing',
                    '/app/services',
                    '/app/locations',
                    '/app/reports',
                    '/app/manage-users',
                    '/app/conditions',
                ].includes(location.pathname)
            );
            set_show_location_dropdown(
                [
                    '/app',
                    '/app/billing',
                    '/app/services',
                    '/app/reports',
                    '/app/conditions',
                ].includes(location.pathname)
            );
        }

        switch (location.pathname) {
            case '/app':
                set_app_top_bar_title('Dashboard');
                break;
            case '/app/locations':
                set_app_top_bar_title('Locations');
                break;
            case '/app/services':
                set_app_top_bar_title('Services');
                break;
            case '/app/manage-users':
                set_app_top_bar_title('Manage Users');
                break;
            case '/app/access-requests':
                set_app_top_bar_title('Access Management');
                break;
            case '/app/conditions':
                set_app_top_bar_title('Conditions');
                break;
            case '/app/billing':
                set_app_top_bar_title('Billing');
                break;
            case '/app/reports':
                set_app_top_bar_title('Reports');
                break;
            case '/app/settings':
                set_app_top_bar_title('Settings');
                break;
            case '/app/help':
                set_app_top_bar_title('Help');
                break;
            default:
                set_app_top_bar_title('');
                break;
        }
    }, [location.pathname]);

    /* multiselect functionality */
    useEffect(() => {
        // Added !show_customer_dropdown to prevent setting of branch locations for customer users
        if (
            user_branch_locations.length &&
            JSON.stringify(previousUserBranchLocations) !==
                JSON.stringify(user_branch_locations)
        ) {
            //if (user_branch_locations.length) {
            const locationStorage = localStorage.getItem('selectedLocations');
            const selectedLocations =
                (locationStorage && JSON.parse(locationStorage)) || [];
            if (
                selectedLocations &&
                Array.isArray(selectedLocations) &&
                selectedLocations.length &&
                selectedLocations.every((selectedLocation) =>
                    user_branch_locations
                        .map((uL) => uL.value)
                        .includes(selectedLocation.value)
                )
            ) {
                if (selectedLocations[0].value === 'all') {
                    set_selected_all_locations(true);
                }
                set_selected_branch_locations(selectedLocations);
                //set_selected_locations_options(selectedLocations);
            } else {
                set_selected_branch_locations(user_branch_locations);
                //set_selected_locations_options(user_br_locations);

                set_selected_all_locations(
                    true && !show_customer_locations_dropdown
                );
            }

            set_locations_options(user_branch_locations);
        }
    }, [user_branch_locations]);

    useEffect(() => {
        if (selected_branch_locations.length) {
            if (selected_branch_locations[0].value === 'all') {
                const checkHasLogbookAccess =
                    user_branch_locations.filter(
                        (userBrLocation) =>
                            userBrLocation.hasLogbookAccount === true
                    ).length || false;
                const checkIsServiceBinder =
                    user_branch_locations.filter(
                        (userBrLocation) =>
                            userBrLocation.isServiceBinder === true
                    ).length || false;
                set_show_service_binder_link(checkIsServiceBinder);
                set_show_logbook_account_link(checkHasLogbookAccess);
            } else {
                const checkHasLogbookAccess =
                    selected_branch_locations.filter(
                        (userBrLocation) =>
                            userBrLocation.hasLogbookAccount === true
                    ).length || false;
                const checkIsServiceBinder =
                    selected_branch_locations.filter(
                        (userBrLocation) =>
                            userBrLocation.isServiceBinder === true
                    ).length || false;
                set_show_service_binder_link(checkIsServiceBinder);
                set_show_logbook_account_link(checkHasLogbookAccess);
            }
            set_selected_locations_options(selected_branch_locations);
        }
    }, [selected_branch_locations]);
    /* multiselect functionality */

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/
    async function fetchNewNotifications() {
        const userNotifications = await getNotifications();
        if (userNotifications.data) {
            setNotifications(userNotifications.data);
            if (initialTotal.current === 0) {
                initialTotal.current = userNotifications.pagination.total;
            } else if (initialTotal.current > 0) {
                setTotalNotificationsCount(userNotifications.pagination.total);
                const countDiff =
                    userNotifications.pagination.total - initialTotal.current;
                if (countDiff > 0) {
                    setNotificationsCount(countDiff);
                    //const setFlag = !new_notifications_flag;
                    //console.log('Setting notification flag ', setFlag);
                    //set_new_notifications_flag(setFlag);
                }
            }
        }
    }

    function generateDemoNotifications() {
        const generateRandomWithinRange = (min, max) =>
            Math.floor(Math.random() * (max - min + 1) + min);

        // product data in same format
        const fakeNotifications = {
            data: [],
            pagination: {
                total: 0,
                perPage: 10,
                pageNum: 1,
            },
            count: generateRandomWithinRange(1, 4), // Generate between 1 and 4 notifications
        };
        // Selected entities
        const entityActivities = {
            Auth: ['Login', 'Logout'],
            Pages: ['Visited the Page'],
            Reports: ['Viewed '],
            'Profile Management': ['Updated Profile'],
        };
        //const users = ['Customer Admin', 'Customer'];
        const entities = Object.keys(entityActivities);
        const numberOfEntities = entities.length;
        for (
            let notifyIndex = 0;
            notifyIndex < fakeNotifications.count;
            notifyIndex++
        ) {
            const randomEntity =
                entities[generateRandomWithinRange(0, numberOfEntities - 1)];
            const entityOperation =
                entityActivities[randomEntity][
                    generateRandomWithinRange(
                        0,
                        entityActivities[randomEntity].length - 1
                    )
                ];
            fakeNotifications['data'][notifyIndex] = {
                entity: randomEntity,
                user: `Customer ${generateRandomWithinRange(1, 5)}`,
                description: entityOperation,
                time: new Date(),
            };
        }

        fakeNotifications['pagination']['total'] += fakeNotifications.count;

        setNotifications(fakeNotifications.data);
        sessionStorage.setItem(
            'fakeNotifications',
            JSON.stringify({ fakeNotifications })
        );
        if (initialTotal.current === 0) {
            initialTotal.current = fakeNotifications.pagination.total;
        } else if (initialTotal.current > 0) {
            setTotalNotificationsCount(fakeNotifications.pagination.total);
            const countDiff =
                fakeNotifications.pagination.total - initialTotal.current;
            if (countDiff > 0) {
                setNotificationsCount(countDiff);
            }
        }
    }

    async function getNotifications() {
        const requestUrl = '/api/v1/logger';
        API.PendingRequestsManager.registerRequest(
            component,
            'getNotifications',
            requestUrl
        );
        const logger = await API.get(requestUrl);
        return logger;
    }

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    function handleUserMenuOpen(event) {
        set_user_menu_elem(event.currentTarget);
    }

    function handleMobileMenuOpen(event) {
        set_mobile_moreEl(event.currentTarget);
    }

    function handleMobileMenuClose() {
        set_mobile_moreEl(null);
    }

    function handleMenuClose() {
        set_user_menu_elem(null);
        handleMobileMenuClose();
    }

    function handleNotificationMenuOpen(event) {
        set_notification_el(event.currentTarget);
        resetNotificationsCount();
    }

    function handleNotificationMenuClose() {
        set_notification_el(null);
        handleMobileMenuClose();
    }

    function handleLocationChange(event, selectedValues, userAction, details) {
        const selectedCount = selectedValues.length;
        // Selected location options excluding all option
        const selectedValuesWithoutAll = selectedValues.filter(
            (selVal) => selVal.value !== 'all'
        );

        // Don't allow select
        const allowSelectAllFlag =
            user_details.role_type !== 'Customer Admin' &&
            user_details.role_type !== 'Customer';

        if (userAction === 'selectOption') {
            if (details.option.value === 'all') {
                set_selected_all_locations(true && allowSelectAllFlag); // Set All flag to true
                /*
        set_selected_branch_locations(userBranchLocations[0]); // Update the list in context
        set_selected_locations_options(userBranchLocations[0]); // Update component option value selection
        */
                set_selected_branch_locations(locations_options); // Update the list in context
                set_selected_locations_options(locations_options); // Update component option value selection

                set_location_dropdown_list_mode('default');
                //localStorage.setItem('selectedLocations', JSON.stringify([userBranchLocations[0]])); // Also update selection list in localStorage
            } else {
                if (selectedCount <= maxAllowedSelectableLocations) {
                    // selectedValuesWithoutAll
                    set_selected_branch_locations(selectedValuesWithoutAll); // Update the list in context
                    set_selected_locations_options(selectedValuesWithoutAll); // Update component option value selection

                    // Shift selected element position
                    //shiftAffectedElementPosition(userAction); // Shift selected elements in selected group

                    let selectAllFlag = false;
                    // Selected options items are equal to the size of list excluding "all" option, so also select "All" option
                    if (
                        userBranchLocations[0].length - 1 ===
                        selectedValuesWithoutAll.length
                    ) {
                        selectAllFlag = true && allowSelectAllFlag;
                        set_selected_branch_locations(userBranchLocations[0]); // Update the list in context
                        set_selected_locations_options(userBranchLocations[0]); // Update component option value selection
                        //localStorage.setItem('selectedLocations', JSON.stringify([userBranchLocations[0]]));
                    }
                    set_selected_all_locations(selectAllFlag);
                } else {
                    displayMultiSelectErrorMsg(
                        `Selection of ${maxAllowedSelectableLocations} locations is allowed at max.`
                    );
                    const locIndex = selectedValues.findIndex(
                        (val) => val.value === details.option.value
                    );
                    if (locIndex > -1) {
                        selectedValues.splice(locIndex, 1);
                    }
                }
            }
        } else if (userAction === 'removeOption') {
            if (
                details.option.value !== 'all' &&
                selectedValuesWithoutAll.length >= 1
            ) {
                if (location_dropdown_list_mode === 'search') {
                    const newlySelectedOption = [details.option];
                    set_location_dropdown_list_mode('default');
                    set_selected_all_locations(false);
                    set_selected_branch_locations(newlySelectedOption); // Update the list in context
                    set_selected_locations_options(newlySelectedOption); // Update component option value selection
                    //shiftAffectedElementPosition('selectOption'); // Shift deselected elements in unselected group
                    //localStorage.setItem('selectedLocations', JSON.stringify(newlySelectedOption));
                } else {
                    set_selected_all_locations(false);
                    set_selected_branch_locations(selectedValuesWithoutAll); // Update the list in context
                    set_selected_locations_options(selectedValuesWithoutAll); // Update component option value selection
                    //shiftAffectedElementPosition(userAction); // Shift deselected elements in unselected group
                    //localStorage.setItem('selectedLocations', JSON.stringify(selectedValuesWithoutAll));
                }
            } else if (selectedValuesWithoutAll.length < 1) {
                set_selected_locations_options([details.option]);
                displayMultiSelectErrorMsg(
                    'At least one location must remain selected'
                );
            } else {
                set_location_dropdown_list_mode('default');
                set_selected_locations_options(
                    location_dropdown_list_mode === 'search'
                        ? locations_options
                        : []
                ); // Update component option value selection
            }
        }
    }

    function handleSyncModal() {
        if (var_mdl_open_sync) {
            set_mdl_open_sync(false);
        } else {
            set_mdl_open_sync(true);
        }
    }

    function closeModalSync() {
        set_mdl_open_sync(false);
    }

    function handleLocationsDropdownClose() {
        if (selected_locations_options.length === 0) {
            set_location_dropdown_list_mode('default');
            set_selected_locations_options(selected_branch_locations);
        }
        // Only sort if All options are not selected and options list is ungrouped
        if (
            !show_grouped_locations_options &&
            ((selected_branch_locations.length === 1 &&
                selected_branch_locations[0].value !== 'all') ||
                (selected_branch_locations.length > 1 &&
                    locations_options.length !==
                        selected_branch_locations.length))
        ) {
            selected_branch_locations.forEach((selectedBranch) => {
                const selectedElementPosition = locations_options.findIndex(
                    (locationOption) =>
                        selectedBranch.value === locationOption.value
                );
                const selectedElement =
                    locations_options[selectedElementPosition];
                locations_options.splice(selectedElementPosition, 1);
                locations_options.splice(1, 0, selectedElement);
            });
            set_locations_options(locations_options);
        }
    }

    function displayMultiSelectErrorMsg(message) {
        enqueueSnackbar(message, {
            variant: 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
        });
        setTimeout(closeSnackbar, 2000);
    }

    function handleBranchChange(event, selectedOption) {
        setSelectedBranch(selectedOption.value);
    }

    function resetNotificationsCount() {
        markActivitiesAsRead();
        setNotificationsCount(0);
        setPrevNotificationsCount(notificationsCount);
        initialTotal.current = totalNotificationsCount;
    }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" color="primary">
                <Toolbar sx={{ alignItems: 'stretch', px: '0 !important' }}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{
                            ml: '0',
                            mr: { xs: 0, md: 2 },
                            display: { lg: 'none' },
                        }}
                        onClick={drawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box
                        onClick={() => history('/app')}
                        sx={{
                            cursor: 'pointer',
                            height: { xs: '56px', sm: '64px' },
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxSizing: 'border-box',
                            borderBottom: { lg: '1px solid #2c4459' },
                            width: { lg: '240px' },
                            backgroundColor: { lg: 'primary.dark' },
                            '& > img': {
                                maxWidth: { xs: '90px', md: '140px' },
                                '@media (max-width: 360px)': {
                                    maxWidth: '72px',
                                },
                            },
                        }}
                    >
                        <img src={Logo} alt="Sprague Customer Portal" />
                    </Box>

                    {(show_branch_dropdown || show_location_dropdown) &&
                        !show_customer_locations_dropdown &&
                        user_details.role_type !== 'Customer' && ( // Show either [branch, locations] or custom groups dropdown
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: { xs: 'center', md: 'center' },
                                    justifyContent: {
                                        xs: 'flex-end',
                                        md: 'flex-start',
                                    },
                                    flex: { xs: '1', md: 'auto' },
                                    pl: { md: '24px' },
                                }}
                            >
                                {show_branch_dropdown && (
                                    <Box
                                        className="select-branch"
                                        sx={{
                                            mr: { xs: '1px', sm: '8px' },
                                            width: '100%',
                                            maxWidth: {
                                                xs: '80px',
                                                sm: '180px',
                                            },
                                            '& .MuiAutocomplete-root': {
                                                backgroundColor:
                                                    'rgba(255,255,255,0.15)',
                                                borderRadius: '4px',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                py: '0 !important',
                                                color: '#fff',
                                                '& .MuiButtonBase-root': {
                                                    color: 'inherit',
                                                },
                                            },
                                            '& .MuiInputLabel-root': {
                                                display: 'none',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline':
                                                { display: 'none' },
                                            '& .MuiAutocomplete-inputFocused::selection':
                                                {
                                                    color: '#3e7099',
                                                    backgroundColor: '#f5f8fa',
                                                },
                                            '@media (max-width: 360px)': {
                                                maxWidth: '70px',
                                            },
                                        }}
                                    >
                                        <CustomAutocompleteGrouping
                                            multipleSelection={false}
                                            disableClearIcon
                                            value={
                                                selected_branch &&
                                                user_branches.find(
                                                    (branch) =>
                                                        branch.value ===
                                                        Number(selected_branch)
                                                )
                                            }
                                            onChange={handleBranchChange}
                                            options={user_branches}
                                            disabled={isUserBranchDisabled}
                                        />
                                    </Box>
                                )}
                                {show_location_dropdown && (
                                    <Box
                                        sx={{
                                            width: { md: '100%' },
                                            maxWidth: {
                                                xs: '80px',
                                                sm: '260px',
                                            },
                                            mr: { xs: '1px', sm: '8px' },
                                            height: { xs: 'auto', sm: 'auto' },
                                            '& .MuiAutocomplete-root': {
                                                height: {
                                                    xs: '100%',
                                                    md: 'auto',
                                                },
                                            },
                                            '@media (max-width: 360px)': {
                                                maxWidth: '70px',
                                            },
                                        }}
                                    >
                                        <LocationsCustomAutocomplete
                                            label=""
                                            name="branchLocationsList"
                                            // options={user_branch_locations}
                                            options={locations_options}
                                            // value={selected_branch_locations}
                                            value={selected_locations_options}
                                            onChange={handleLocationChange}
                                            onClose={
                                                handleLocationsDropdownClose
                                            }
                                            listMode={
                                                location_dropdown_list_mode
                                            }
                                            setListMode={
                                                set_location_dropdown_list_mode
                                            }
                                        />
                                    </Box>
                                )}
                            </Box>
                        )}

                    {
                        /* Begin: Customer dropdown */
                        show_customer_locations_dropdown && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: { xs: 'center', md: 'center' },
                                    justifyContent: {
                                        xs: 'flex-end',
                                        md: 'flex-start',
                                    },
                                    flex: { xs: '1', md: 'auto' },
                                    pl: { md: '24px' },
                                }}
                            >
                                <Box
                                    className="select-custom-groups"
                                    sx={{
                                        mr: { xs: '1px', sm: '8px' },
                                        width: '100%',
                                        maxWidth: { xs: '140px', sm: '280px' },
                                        '& .MuiAutocomplete-root': {
                                            backgroundColor:
                                                'rgba(255,255,255,0.15)',
                                            borderRadius: '4px',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            py: '0 !important',
                                            color: '#fff',
                                            '& .MuiButtonBase-root': {
                                                color: 'inherit',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            display: 'none',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            display: 'none',
                                        },
                                        '& .MuiAutocomplete-inputFocused::selection':
                                            {
                                                color: '#3e7099',
                                                backgroundColor: '#f5f8fa',
                                            },
                                        '@media (max-width: 360px)': {
                                            maxWidth: '120px',
                                        },
                                    }}
                                >
                                    <LocationsAutocompleteDropdownList
                                        disableClearIcon
                                        multipleSelection={true}
                                        {...(show_grouped_locations_options && {
                                            set_values:
                                                set_selected_locations_options,
                                        })}
                                        options={locations_options}
                                        value={selected_locations_options}
                                        onChange={handleLocationChange}
                                        onClose={handleLocationsDropdownClose}
                                        allSelectedByDefault={true}
                                        allowedItemsSelection={
                                            maxAllowedSelectableLocations
                                        }
                                        listMode={location_dropdown_list_mode}
                                        setListMode={
                                            set_location_dropdown_list_mode
                                        }
                                        groupByOptions={
                                            show_grouped_locations_options
                                        }
                                        groupSelectionPostCallback={(
                                            ...selectedLocationsListFromComponent
                                        ) =>
                                            selectedLocationsListFromComponent.length >
                                                0 &&
                                            set_selected_branch_locations(
                                                selectedLocationsListFromComponent
                                            )
                                        }
                                    />
                                </Box>
                            </Box>
                        )
                        /* End: Customer dropdown */
                    }

                    {!show_branch_dropdown &&
                        !show_location_dropdown &&
                        !show_customer_locations_dropdown && (
                            <Box
                                sx={{
                                    display: { xs: 'none', sm: 'flex' },
                                    alignItems: 'center',
                                    pl: '24px',
                                }}
                            >
                                <Box>
                                    <Box
                                        sx={{ color: '#fff', fontSize: '20px' }}
                                    >
                                        {app_top_bar_title}
                                    </Box>
                                    <Box
                                        sx={{
                                            color: 'rgba(255,255, 255, 0.75)',
                                            fontSize: '12px',
                                            display: 'flex',
                                            mt: '2px',
                                            letterSpacing: '0.75px',
                                            '& > div': { mr: '8px' },
                                        }}
                                    >
                                        <Box>
                                            {user_details
                                                ? `${user_details.firstName} ${user_details.lastName}`
                                                : ''}
                                        </Box>
                                        <Box>
                                            {user_details
                                                ? user_details.email
                                                : ''}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        )}

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'block' },
                        }}
                    />

                    <Box
                        component="ul"
                        sx={{
                            display: 'flex',
                            listStyle: 'none',
                            p: 0,
                            m: 0,
                            ml: 'auto',
                            '& > li': {
                                borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                                boxShadow:
                                    'inset 1px 0 rgba(255, 255, 255, 0.12)',
                            },
                        }}
                    >
                        <Box
                            component="li"
                            sx={{ display: { xs: 'none', md: 'block' } }}
                        >
                            <LastLoggedIn userMenu={false} />
                        </Box>
                        {show_logbook_account_link && (
                            <Box
                                component="li"
                                sx={{ display: { xs: 'none', lg: 'flex' } }}
                            >
                                <Button
                                    sx={{ height: '100%', px: 2 }}
                                    color="inherit"
                                    href="https://mylogbook.spraguepest.com"
                                    target="_blank"
                                >
                                    Logbook
                                </Button>
                            </Box>
                        )}
                        {show_service_binder_link && (
                            <Box
                                component="li"
                                sx={{ display: { xs: 'none', lg: 'flex' } }}
                            >
                                <Button
                                    sx={{ height: '100%', px: 2 }}
                                    color="inherit"
                                    href="https://mylogbook.spraguepest.com"
                                    target="_blank"
                                >
                                    Service Binder
                                </Button>
                            </Box>
                        )}
                        {show_sync_locations_icon && (
                            <Box
                                component="li"
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                <Tooltip title="Sync Locations" arrow>
                                    <IconButton
                                        color="inherit"
                                        onClick={handleSyncModal}
                                        size="large"
                                    >
                                        <AutorenewIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                        <Box
                            component="li"
                            sx={{ display: { xs: 'none', lg: 'flex' } }}
                        >
                            <IconButton
                                size="large"
                                color="inherit"
                                onClick={handleNotificationMenuOpen}
                            >
                                <Badge
                                    badgeContent={notificationsCount}
                                    color="error"
                                >
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Box>
                        <Box
                            component="li"
                            sx={{ display: { xs: 'none', lg: 'flex' } }}
                        >
                            <IconButton
                                onClick={handleUserMenuOpen}
                                color="inherit"
                                size="large"
                            >
                                <AccountCircleIcon />
                            </IconButton>
                        </Box>
                        <Box
                            component="li"
                            sx={{ display: { xs: 'flex', lg: 'none' } }}
                        >
                            <IconButton
                                size="large"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>

            <MobileMenu
                anchorEl={var_mobile_moreEl}
                handleMenuOpen={isMobileMenuOpen}
                handleMobileMenuClose={handleMobileMenuClose}
                handleUserMenuOpen={handleUserMenuOpen}
                handleNotificationMenuOpen={handleNotificationMenuOpen}
                notificationsList={notifications}
            />

            <NotificationMenu
                anchorEl={var_notification_el}
                handleMenuOpen={isNotificationsOpen}
                handleMenuClose={handleNotificationMenuClose}
                openMdlNotifications={openMdlNotifications}
                closeMdlNotifications={closeMdlNotifications}
                notificationsList={notifications}
                notificationsCount={prevNotificationsCount}
                newNotificationsCount={notificationsCount}
                totalNotifications={totalNotificationsCount}
            />

            <UserMenu
                anchorEl={var_user_menu_elem}
                handleMenuOpen={isMenuOpen}
                handleMenuClose={handleMenuClose}
                openMdlPreferences={openMdlPreferences}
            />

            {show_sync_locations_icon && (
                <ModalSyncLocationsData
                    closeModal={closeModalSync}
                    openModal={var_mdl_open_sync}
                />
            )}
        </Box>
    );
}

AppTopBar.defaultProps = {
    drawerToggle: () => {},
    openMdlNotifications: () => {},
    closeMdlNotifications: () => {},
};

AppTopBar.propTypes = {
    drawerToggle: PropTypes.func,
    openMdlNotifications: PropTypes.func,
    closeMdlNotifications: PropTypes.func,
};

export default AppTopBar;
