import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { styled } from '@mui/material/styles';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import {
    Checkbox,
    TextField,
    Typography,
    ListItem,
    ListItemText,
    Popper,
    ListItemIcon,
    Box,
} from '@mui/material';

const LISTBOX_PADDING = 8;
const OuterElementContext = React.createContext({});
const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

/* Pagination */
const LOADING = 1;
const LOADED = 2;
let itemStatusMap = {};

const isItemLoaded = (index) => !!itemStatusMap[index];
const loadMoreItems = (startIndex, stopIndex) => {
    for (let index = startIndex; index <= stopIndex; index++) {
        itemStatusMap[index] = LOADING;
    }
    return new Promise((resolve) =>
        setTimeout(() => {
            for (let index = startIndex; index <= stopIndex; index++) {
                itemStatusMap[index] = LOADED;
            }
            resolve();
        }, 200)
    );
};

/* Pagination */

const StyledPopper = styled(Popper)({
    padding: '0',
    minWidth: '240px',
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        padding: '0',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

const ListboxComponent = React.forwardRef(
    function ListboxComponent(props, ref) {
        ListboxComponent.propTypes = { children: PropTypes.node };
        const {
            children,
            allOptionsWithoutAllOption,
            allOptionsSelected,
            selectedoptions,
            listMode,
            ...other
        } = props;
        const itemData = [];
        children.forEach((item) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        });

        /* Pagination */
        const itemCount = itemData.length;
        const itemSize = 36;
        const getChildSize = (child) => {
            if (child.hasOwnProperty('group')) {
                return 48;
            }
            return itemSize;
        };
        const getHeight = () => {
            if (itemCount > 8) {
                return 8 * itemSize;
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
        };

        // ListViewItem component
        const ListViewItem = (props) => {
            const { index, dataSet, selectedOptions, style } = props;
            const inlineStyle = {
                ...style,
                top: style.top + LISTBOX_PADDING,
                paddingLeft: '8px',
                paddingRight: '8px',
                minWidth: '240px',
            };

            // Checks if checkbox should appear checked
            const isChecked = () => {
                /* 
            If All options are selected then all list items except "All" option are displayed as checked and disabled
            In order to allow user to perform custom user selection, the searched options should be displayed as unchecked and enabled
            */
                if (listMode === 'search' && allOptionsSelected) {
                    return false;
                }
                // Display option as checked if option is among selected elements or if all elements are selected
                return selectedOptions.findIndex(
                    (selected) => selected.value === dataSet[1].value
                ) > -1 ||
                    selectedoptions.length ===
                        allOptionsWithoutAllOption.length + 1
                    ? true
                    : false;
            };

            return itemStatusMap[index] === LOADED ? (
                <ListItem
                    key={index}
                    component="div"
                    {...dataSet[0]}
                    style={inlineStyle}
                >
                    <ListItemIcon sx={{ minWidth: '36px' }}>
                        <Checkbox
                            checked={isChecked()}
                            // Conditional props for All options
                            {...(index === 0 &&
                                children[0][1].value === 'all' &&
                                selectedOptions.length && {
                                    indeterminate:
                                        selectedOptions.length !==
                                        allOptionsWithoutAllOption.length + 1
                                            ? true
                                            : false,
                                })}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={dataSet[1].label}
                        primaryTypographyProps={{
                            style: {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            },
                        }}
                        secondary={dataSet[1].address}
                        secondaryTypographyProps={{
                            style: {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            },
                        }}
                    />
                </ListItem>
            ) : (
                <Typography
                    component="li"
                    noWrap
                    {...dataSet[0]}
                    style={inlineStyle}
                >
                    ...Loading
                </Typography>
            );
        };

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={itemCount}
                        loadMoreItems={loadMoreItems}
                    >
                        {({ onItemsRendered, ref }) => (
                            <VariableSizeList
                                itemData={itemData}
                                itemCount={itemCount}
                                height={getHeight() + 2 * LISTBOX_PADDING}
                                width="100%"
                                ref={ref}
                                outerElementType={OuterElementType}
                                innerElementType="ul"
                                itemSize={(index) =>
                                    getChildSize(itemData[index])
                                }
                                onItemsRendered={onItemsRendered}
                            >
                                {({ data, index, style }) => (
                                    <ListViewItem
                                        dataSet={data[index]}
                                        index={index}
                                        style={style}
                                        selectedOptions={selectedoptions}
                                    />
                                )}
                            </VariableSizeList>
                        )}
                    </InfiniteLoader>
                </OuterElementContext.Provider>
            </div>
        );
    }
);

function LocationsCustomAutocomplete({
    label,
    value,
    name,
    onChange,
    onClose,
    options,
    listMode = 'default',
    setListMode,
    error = null,
    disabled = false,
    variant = 'outlined',
    placeholder,
    ...other
}) {
    /* VARIABLE DECLERATION
    -------------------------------------------------------------------------------------*/
    const [textFieldBlurred, setTextFieldBlurred] = useState(true);
    const [searchText, setSearchText] = useState('');

    // Internal component flag when all options are selected
    let allOptionsSelected = false;
    // List of all dropdown options excluding "All" option
    const allOptionsWithoutAllOption = options.filter(
        (option) => option.value !== 'all'
    );

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    const onChangeEventAction = function () {
        if (typeof onChange === 'function') {
            onChange.apply(null, arguments);
        }
        setTextFieldBlurred(true);
    };

    const onCloseEventAction = function () {
        if (typeof onClose === 'function') {
            onClose.apply(null, arguments);
            setSearchText('');
            setTextFieldBlurred(true);
        }
    };

    /* RENDER APP
    -------------------------------------------------------------------------------------*/

    // If "All" option is selected, then selected options value should be the list of options
    if (
        value &&
        Array.isArray(value) &&
        value.length > 0 &&
        value[0].value === 'all'
    ) {
        value = options;
        allOptionsSelected = true;
    }

    /* LISTBOX COMPONENT
    -------------------------------------------------------------------------------------*/
    /* Begin: ListboxComponent */

    /* End: ListboxComponent */

    /* COMPONENT JSX
    -------------------------------------------------------------------------------------*/
    return (
        <>
            <Autocomplete
                fullWidth
                multiple
                disableCloseOnSelect
                PopperComponent={StyledPopper}
                ListboxComponent={ListboxComponent}
                ListboxProps={{
                    allOptionsWithoutAllOption,
                    listMode,
                    allOptionsSelected,
                    selectedoptions: value,
                    placement: 'bottom-end',
                }}
                options={options}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, optionValue) =>
                    option &&
                    optionValue &&
                    option?.value &&
                    optionValue?.value &&
                    option.value === optionValue.value
                }
                value={value}
                disableClearable={true}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant={variant}
                        label={label}
                        placeholder={placeholder}
                        onClick={() => setTextFieldBlurred(false)}
                        onBlur={() => {
                            setTextFieldBlurred(true);
                            // Return to default mode if the user focuses out of selection search field
                            if (setListMode) setListMode('default');
                        }}
                    />
                )}
                inputValue={searchText}
                onInputChange={(event, inputFieldValue, reason) =>
                    reason !== 'reset' && setSearchText(inputFieldValue)
                }
                onKeyUpCapture={(event) =>
                    setListMode &&
                    setListMode(
                        event.target.value !== '' ? 'search' : 'default'
                    )
                }
                onKeyDownCapture={(event) =>
                    event.key === 'Backspace' && event.stopPropagation()
                }
                // If all options are selected then disable entire list except "All" option
                getOptionDisabled={(option) =>
                    listMode === 'default' &&
                    allOptionsSelected &&
                    option.value !== 'all'
                        ? true
                        : false
                }
                renderOption={(props, option) => [props, option]}
                onChange={onChangeEventAction}
                onClose={onCloseEventAction}
                renderTags={(tagValues, getTagProps) =>
                    textFieldBlurred && searchText === '' ? (
                        <Box
                            component="span"
                            sx={{
                                color: 'white',
                                zIndex: 51,
                                position: { xs: 'absolute', sm: 'static' },
                            }}
                        >
                            <Box
                                component="span"
                                sx={{ display: { xs: 'none', sm: 'inline' } }}
                            >
                                Selected
                            </Box>{' '}
                            {`${
                                allOptionsWithoutAllOption.length ===
                                value.length - 1
                                    ? 'All'
                                    : tagValues.length
                            }`}
                        </Box>
                    ) : (
                        ''
                    )
                }
                sx={{
                    color: '#fff',
                    backgroundColor: 'rgba(255, 255, 255, 0.15)',
                    '&:hover': { backgroundColor: 'rgba(255,255,255, 0.25)' },
                    borderRadius: 1,
                    '& .MuiInputBase-root': { py: '0', color: '#fff' },
                    '& .MuiInputBase-input': { color: '#fff' },
                    '& .MuiOutlinedInput-notchedOutline': { display: 'none' },
                    '& .MuiAutocomplete-endAdornment .MuiButtonBase-root': {
                        color: '#fff',
                    },
                }}
            />
        </>
    );
}

LocationsCustomAutocomplete.defaultProps = {
    listMode: 'default',
    error: null,
    disabled: false,
    variant: 'outlined',
};

LocationsCustomAutocomplete.propTypes = {
    label: PropTypes.string,
    value: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
                label: PropTypes.string,
            }),
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string,
                ]),
                label: PropTypes.string,
                isServiceBinder: PropTypes.bool,
                hasLogbookAccount: PropTypes.bool,
                address: PropTypes.string,
            }),
        ])
    ),
    name: PropTypes.string,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            label: PropTypes.string,
        })
    ),
    other: PropTypes.any,
};

export default LocationsCustomAutocomplete;
