import API from './ServerAPIs';
// Get number of activities read by the user
function getReadActivityCount() {
    return Number(sessionStorage.getItem('readActivityCount') ?? 0);
}

// Mark number of read activities count
function markActivitiesAsRead() {
    sessionStorage.setItem('readActivityCount', getActivityCount());
}

// Get list of activities maintained on the frontend side (e.g. user actions)
function getActivityList() {
    let activityList = sessionStorage.getItem('activityList');
    if (Array.isArray(JSON.parse(activityList))) {
        activityList = JSON.parse(activityList);
    } else {
        activityList = [];
    }
    return activityList;
}

// Log user's activity (on frontend, notify the user) and maintain the list locally
// (entity = Related Activity, description = Detail of activity)
function logActivity(entity = '', description = '') {
    let activityList = getActivityList();
    activityList.unshift({
        entity,
        description,
        time: new Date(),
    });
    /* 
        Also log this activity to the server if required. 
        For now, only page visit activity is being logged to the server 
        and the list is maintained locally
    */
    sessionStorage.setItem('activityList', JSON.stringify(activityList));
    sessionStorage.setItem('activityCount', getActivityCount() + 1);
}

// Log page visit activity
function logPageVisit(
    page = '',
    entity = '',
    pageComponent = '',
    userDetails = null
) {
    if (
        userDetails &&
        userDetails.type !== 'sprague' &&
        userDetails.type !== 'demo' &&
        !(
            'isImpersonate' in userDetails &&
            userDetails['isImpersonate'] === true
        )
    ) {
        // Disable the following
        /*
        if (page !== '') {
            logActivity((entity !== '' ? entity : page), `Visited page ${page}`)        
        }
        */
        const requestUrl = `/api/v1/logger/${page}`;
        if (pageComponent !== '') {
            API.PendingRequestsManager.registerRequest(
                pageComponent,
                'logPageVisit',
                requestUrl
            );
            API.post(requestUrl);
        }
    }
}

// Activity account
function getActivityCount() {
    return Number(sessionStorage.getItem('activityCount') ?? 0);
}

// Get range of activities from activity list
function getActivityListChunk(offset = 0, pageSize = 10) {
    //return getActivityList().slice(0, 10);
    return getActivityList().slice(offset, pageSize);
}

function getRecentActivityList() {
    //return getActivityList().slice(0, getActivityCount() - getReadActivityCount());
    return getActivityListChunk(0, getActivityCount() - getReadActivityCount());
}

export {
    logActivity,
    getActivityList,
    getRecentActivityList,
    markActivitiesAsRead,
    getActivityListChunk,
    logPageVisit,
};
