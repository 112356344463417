import React, { useEffect, useState } from 'react';
import Controls from '../../../Components/Controls/Controls';
import { useForm, Form } from '../../../Components/UseForm';
import API from '../../../libs/ServerAPIs';
import Modal from '../../../Components/Modal';
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Divider,
    Grid,
    Button,
    Alert,
    AlertTitle,
    Slide,
} from '@mui/material';

import { Input, Label } from './styles';
import { useData } from '../../../Context/Context';
import { useSnackbar } from 'notistack';
// import { LoadingButton } from '@mui/lab';

function TabProfile({ var_tabValue }) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const { userData, userBranches } = useData(useData);
    const [user_details] = userData;
    const [user_branches] = userBranches;

    const initValues = {
        id: 0,
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        city: '',
        state: '',
        zip: '',
        address: '',
        locationNumber: '',
        role: '',
        userRole: '',
        isAgreed: false,
        currentPassword: '***************',
        textMessages: false,
        pushNotifications: true,
        phoneCalls: false,
        default_branch: user_details.defaultBranch,
        disableRoleDropDown: true,
    };
    const [image, setImage] = useState(null);
    // const [var_save_default_branch_loading, set_save_default_branch_loading] = useState(false);
    // const [var_save_email_notification_loading, set_save_email_notification_loading] = useState(false);
    const {
        var_values,
        set_values,
        var_errors,
        set_errors,
        resetForm,
        handleInputChange,
    } = useForm(initValues);
    const { enqueueSnackbar } = useSnackbar();
    const [var_mdl_changePassword_open, set_mdl_changePassword_open] =
        useState(false);

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        if (var_tabValue === 'profile') {
            if (user_details && user_branches) {
                set_values({
                    ...var_values,
                    id: user_details.id,
                    first_name: user_details.firstName || '',
                    last_name: user_details.lastName || '',
                    email: user_details.email || '',
                    permission: user_details.permission || '',
                    address: user_details.address || '',
                    state: user_details.state || '',
                    zip: user_details.zip || '',
                    phone_number: user_details.phoneNumber || '',
                    city: user_details.city || '',
                    role: user_details.role || '',
                    default_branch:
                        (user_details && user_details.defaultBranch) ||
                        (user_branches.length && user_branches[0].value),
                });
            }
        }
    }, [var_tabValue]);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    function showSnackbar(variant, message) {
        enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            TransitionComponent: Slide,
        });
    }

    const handleChangeFile = (event) => {
        const { value } = event.target;
        if (value) {
            setImage(URL.createObjectURL(event.target.files[0]));
            var formData = new FormData();
            formData.append('profile_picture', event.target.files[0]);
            API.put('/api/v1/user/update-profile-picture', formData, true)
                .then((res) => {
                    showSnackbar(res.error ? 'error' : 'success', res.message);
                })
                .catch((error) => {
                    // console.log("Error::::", error);
                    return { error: true };
                });
        }
    };
    function openMdlChangePassword() {
        set_mdl_changePassword_open(true);
    }

    function closeMdlChangePassword() {
        set_mdl_changePassword_open(false);
    }

    const handlePasswordUpdate = (e) => {
        let field_errors = {};
        const no_match_msg = 'Passwords do not match';

        ['oldPassword', 'newPassword', 'confirmNewPassword'].forEach(
            (field) => {
                if (field in var_values && var_values[field].trim() === '') {
                    field_errors = {
                        ...field_errors,
                        [field]: `This field must be provided with some value`,
                    };
                }

                if (
                    field === 'newPassword' &&
                    var_values.newPassword !== var_values.confirmNewPassword
                ) {
                    field_errors = {
                        ...field_errors,
                        newPassword: no_match_msg,
                        cNewPassword: no_match_msg,
                    };
                }
            }
        );

        set_errors(field_errors);
        // if no errors then send update password request
        if (Object.keys(field_errors).length === 0) {
            API.put('/api/v1/user/update-password', {
                confirm_password: var_values.confirmNewPassword,
                old_password: var_values.oldPassword,
                new_password: var_values.newPassword,
            }).then((response) => {
                showSnackbar(
                    response.error ? 'error' : 'success',
                    response.message
                );
                if (!response.error) {
                    resetForm();
                    closeMdlChangePassword();
                }
            });
        }
    };

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <>
            <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12} md={4} xl={4}>
                    <Card sx={{ height: '100%' }}>
                        <CardHeader
                            title="Account information"
                            subheader="Update profile picture and password."
                        />

                        <Divider />

                        <CardContent sx={{ py: '30px' }}>
                            <Avatar
                                src={
                                    image ??
                                    '/api/v1/user/get-profile-picture?id=' +
                                        user_details.userID +
                                        '&time=' +
                                        new Date(
                                            user_details.updatedOn
                                        ).getTime()
                                }
                                sx={{ width: 200, height: 200, m: '0 auto' }}
                                variant="circular"
                            />
                        </CardContent>

                        <CardActions
                            sx={{ px: '24px', pb: '20px', display: 'block' }}
                        >
                            <Label htmlFor="upload-profile-pic">
                                <Input
                                    accept="image/*"
                                    id="upload-profile-pic"
                                    multiple={false}
                                    type="file"
                                    onChange={handleChangeFile}
                                />
                                <Button
                                    variant="contained"
                                    component="span"
                                    color="secondary"
                                    fullWidth
                                >
                                    Upload Picture
                                </Button>
                            </Label>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                type="button"
                                onClick={openMdlChangePassword}
                                sx={{ height: '100%', mt: '16px' }}
                            >
                                Change password
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} md={8} xl={8}>
                    <Card>
                        <CardHeader
                            title="Profile"
                            subheader="The information cannot be edited."
                        />

                        <Divider />

                        <CardContent sx={{ padding: '24px' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Controls.Input
                                        name="first_name"
                                        label="First Name"
                                        value={var_values.first_name}
                                        onChange={handleInputChange}
                                        error={var_errors.firstName}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Controls.Input
                                        name="last_name"
                                        label="Last Name"
                                        value={var_values.last_name}
                                        onChange={handleInputChange}
                                        error={var_errors.lastName}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={9}>
                                    <Controls.Input
                                        name="address"
                                        label="Street address"
                                        value={var_values.address}
                                        onChange={handleInputChange}
                                        error={var_errors.address}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Controls.Input
                                        name="role"
                                        label="User Role"
                                        value={var_values.role}
                                        disabled={true}
                                        onChange={handleInputChange}
                                        error={var_errors.role}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controls.Input
                                        name="city"
                                        label="City"
                                        value={var_values.city}
                                        onChange={handleInputChange}
                                        error={var_errors.city}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <Controls.Input
                                        name="state"
                                        label="State"
                                        value={var_values.state}
                                        onChange={handleInputChange}
                                        error={var_errors.state}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <Controls.Input
                                        name="zip"
                                        label="Zip"
                                        value={var_values.zip}
                                        onChange={handleInputChange}
                                        error={var_errors.zip}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Controls.Input
                                        name="email"
                                        label="Email"
                                        value={var_values.email}
                                        onChange={handleInputChange}
                                        error={var_errors.email}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Controls.Input
                                        name="phone_number"
                                        label="Phone Number"
                                        value={var_values.phone_number}
                                        onChange={handleInputChange}
                                        error={var_errors.phoneNumber}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        <AlertTitle>
                                            Do you want to update your profile?
                                        </AlertTitle>
                                        Give us a call.​ —{' '}
                                        <strong>
                                            24/7 HOTLINE: 800.272.4988
                                        </strong>
                                    </Alert>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/***** MODAL: CHANGE PASSWORD ********************************************************/}
            <Modal
                title="Change password"
                openModal={var_mdl_changePassword_open}
                closeModal={closeMdlChangePassword}
                width="xs"
                dialogActions={
                    <>
                        <Button onClick={closeMdlChangePassword}>Cancel</Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handlePasswordUpdate}
                        >
                            Save
                        </Button>
                    </>
                }
            >
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controls.Input
                                name="oldPassword"
                                label="Old password"
                                value={var_values.oldPassword}
                                error={var_errors.oldPassword}
                                onChange={handleInputChange}
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controls.Input
                                name="newPassword"
                                label="New password"
                                value={var_values.newPassword}
                                error={var_errors.newPassword}
                                onChange={handleInputChange}
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controls.Input
                                name="confirmNewPassword"
                                label="Confirm new password"
                                value={var_values.confirmNewPassword}
                                error={var_errors.confirmNewPassword}
                                onChange={handleInputChange}
                                type="password"
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Modal>

            {/***** END MODAL: CHANGE PASSWORD ****************************************************/}
        </>
    );
}

export default TabProfile;
