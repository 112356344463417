import React, { useState, useEffect } from 'react';
import { useData } from '../../Context/Context';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabProfile from './Components/TabProfile';
import TabDashboard from './Components/TabDashboard';
import Modal from '../Modal';
import { Tab, Button, Box } from '@mui/material';

function ModalPreferences({ openModal, closeModal }) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const [var_tabValue, set_tabValue] = useState('profile');
    const { frame } = useData(useData);
    const [, set_visibleFrame] = frame;

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        set_visibleFrame(true);
    }, [set_visibleFrame]);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    function handleTabChange(event, newValue) {
        set_tabValue(newValue);
    }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <Modal
            title="Profile Settings"
            openModal={openModal}
            closeModal={closeModal}
            contentNoPadding
            width="lg"
            dialogActions={
                <>
                    <Button onClick={closeModal}>Close</Button>
                </>
            }
        >
            <Box sx={{ p: '16px 24px', backgroundColor: '#f4f6f8' }}>
                <TabContext value={var_tabValue}>
                    <TabList onChange={handleTabChange}>
                        <Tab label="Profile" value="profile" />
                        <Tab label="Dashboard" value="dashboard" />
                    </TabList>

                    <TabPanel value="profile" sx={{ p: '24px 0' }}>
                        <TabProfile var_tabValue={var_tabValue} />
                    </TabPanel>

                    <TabPanel value="dashboard" sx={{ p: '24px 0 0' }}>
                        <TabDashboard
                            var_tabValue={var_tabValue}
                            closeModal={closeModal}
                        />
                    </TabPanel>
                </TabContext>
            </Box>
        </Modal>
    );
}

export default ModalPreferences;
