import React, { useState, useEffect } from 'react';
import Modal from '../../Modal';
import { Box, Button, Typography, Slide } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useData } from '../../../Context/Context';
import API from '../../../libs/ServerAPIs';
import Spinner from '../../Spinner';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import moment from 'moment';
import MomentTZ from 'moment-timezone';

function ModalSyncLocationsData({ openModal, closeModal, userID = null }) {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const { syncedUserLocations } = useData(useData);
    const [syncedUserLocationData, setSyncedUserLocationData] =
        syncedUserLocations;
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        if (openModal && (!syncedUserLocationData.length || userID)) {
            getSyncedLocationData();
        }
    }, [openModal]);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/
    async function getSyncedLocationData() {
        setLoading(true);
        return API.get(
            !userID
                ? '/api/v1/dashboard/location-status'
                : `/api/v1/user-impersonate/dashboard-location-status/${userID}`
        )
            .then((locations) => {
                if (!locations.error) {
                    setSyncedUserLocationData(locations);
                }
                setLoading(false);
                return locations;
            })
            .catch((error) => {
                setLoading(false);
                // console.log("Error::::", error);
                return { error: true };
            });
    }

    async function handleSync(location, all = false) {
        let locations = null;
        if (all) {
            locations = syncedUserLocationData.map((l) => l.id);
            const syncLocation = syncedUserLocationData.map((l) => ({
                ...l,
                status: 'Syncing...',
            }));
            setSyncedUserLocationData(syncLocation);
        } else {
            locations = [location.id];
            const syncLocation = syncedUserLocationData.map((l) => {
                if (l.id === location.id) {
                    l.status = 'Syncing...';
                }
                return l;
            });
            setSyncedUserLocationData(syncLocation);
        }

        if (locations) {
            const requestUrl = '/api/v1/pestpac/sync';

            const response = await API.post(
                requestUrl,
                { locations },
                false,
                false
            );

            if (all) {
                const syncLocation = syncedUserLocationData.map((l) => ({
                    ...l,
                    status: !response.error
                        ? 'Synced Successfully'
                        : 'Not Synced',
                    syncDate: !response.error ? response.syncDate : l.syncDate,
                }));
                setSyncedUserLocationData(syncLocation);
            } else {
                const syncLocation = syncedUserLocationData.map((l) => {
                    if (l.id === location.id) {
                        l.status = !response.error
                            ? 'Synced Successfully'
                            : 'Not Synced';
                        l.syncDate = !response.error
                            ? response.syncDate
                            : l.syncDate;
                    }
                    return l;
                });
                setSyncedUserLocationData(syncLocation);
            }

            showSnackbar(
                !response.error ? 'success' : 'error',
                response.message
            );
        }
    }

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    function showSnackbar(variant, message) {
        enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            TransitionComponent: Slide,
        });
    }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/

    return (
        <Modal
            title="Sync Locations Data"
            openModal={openModal}
            closeModal={closeModal}
            width="sm"
            dialogActions={
                <>
                    <Button onClick={closeModal}>Close</Button>
                </>
            }
        >
            <Box>
                {!loading && (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '8px 0',
                            }}
                        >
                            <Typography
                                id="modal-modal-title"
                                style={{ verticalAlign: 'bottom' }}
                                variant="h5"
                                component="h2"
                            >
                                Locations
                            </Typography>
                            <div>
                                <LoadingButton
                                    id="all"
                                    loading={loading}
                                    disabled={loading}
                                    variant="contained"
                                    onClick={() => {
                                        if (!loading) {
                                            handleSync('all', 'All Locations');
                                        }
                                    }}
                                >
                                    Sync all
                                </LoadingButton>
                            </div>
                        </div>

                        {syncedUserLocationData.map((location) => (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '8px 0',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '33.3%',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div style={{ fontSize: '14px' }}>
                                        {location?.number}# {location?.name}
                                    </div>
                                    <div style={{ fontSize: '12px' }}>
                                        {location?.address}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '33.3%',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div
                                        id="syncStatus"
                                        style={{ fontSize: '14px' }}
                                    >
                                        <span
                                            style={{ verticalAlign: 'super' }}
                                        >
                                            Status:
                                        </span>
                                        {location?.status?.toLowerCase() ===
                                        'not synced' ? (
                                            <HighlightOffIcon fontSize="small" />
                                        ) : (
                                            <CheckCircleOutlineIcon fontSize="small" />
                                        )}
                                        <span
                                            style={{ verticalAlign: 'super' }}
                                        >
                                            {location?.status || 'Synced'}
                                        </span>
                                    </div>
                                    <div style={{ fontSize: '12px' }}>
                                        Last Sync:{' '}
                                        {location?.syncDate
                                            ? `${moment
                                                  .utc(location.syncDate)
                                                  .local()
                                                  .format(
                                                      'MM/DD/YYYY hh:mm A'
                                                  )} ${MomentTZ.tz(
                                                  MomentTZ.tz.guess()
                                              ).zoneName()}`
                                            : 'Not Found'}
                                    </div>
                                </div>
                                <LoadingButton
                                    sx={{ width: '94px', maxHeight: '35px' }}
                                    id="all"
                                    variant="contained"
                                    onClick={() => {
                                        if (
                                            !location.status ||
                                            location.status !== 'Syncing...'
                                        ) {
                                            handleSync(location);
                                        }
                                    }}
                                    loading={
                                        location.status &&
                                        location.status === 'Syncing...'
                                    }
                                    disabled={
                                        location.status &&
                                        location.status === 'Syncing...'
                                    }
                                >
                                    Sync
                                </LoadingButton>
                            </div>
                        ))}
                    </>
                )}
                {loading && <Spinner />}
            </Box>
        </Modal>
    );
}

export default ModalSyncLocationsData;
