import React, {
    useState,
    createContext,
    useContext,
    useEffect,
    useRef,
} from 'react';
import API from '../libs/ServerAPIs';

const AppContext = createContext();

const ContextProvider = ({ children }) => {
    /* VARIABLE DECELERATION
    -------------------------------------------------------------------------------------*/
    const [var_visibleFrame, set_visibleFrame] = useState(false);
    const [var_drawer_open, set_drawer_open] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isStrongPassFlow, setIsStrongPassFlow] = useState(false);
    const [isLoadingData, set_loading_data] = useState(false);
    const [var_refresh_data, set_refresh_data] = useState(false);
    const [user_details, setUserDetails] = useState(null);
    const [isMobile, set_isMobile] = useState(window.innerWidth <= 768);
    const [primary_user, setPrimaryUser] = useState(null);
    const [secondary_user, setSecondaryUser] = useState(null);
    const [selected_branch, setSelectedBranch] = useState(null);
    const [user_branches, set_user_branches] = useState([]);
    const [user_locations_with_branches, setUserLocationsWithBranches] =
        useState({});
    const [user_branch_locations, set_user_branch_locations] = useState([]);
    const [selected_branch_locations, set_selected_branch_locations] = useState(
        []
    );
    const [var_firstVisitAfterLogin, set_firstVisitAfterLogin] = useState(false);
    const [selected_all_locations, set_selected_all_locations] =
        useState(false); // Flag set to true when all locations in dropdown are selected
    const [show_branch_dropdown, set_show_branch_dropdown] = useState(false); // Branch selection dropdown in App top bar for sprague users
    const [show_location_dropdown, set_show_location_dropdown] =
        useState(false); // Locations selection dropdown in App top bar for sprague users
    const [
        show_customer_locations_dropdown,
        set_show_customer_locations_dropdown,
    ] = useState(false); // Locations selection dropdown in App top bar for customer admin / customer user
    const [show_service_binder_link, set_show_service_binder_link] =
        useState(false);
    const [show_logbook_account_link, set_show_logbook_account_link] =
        useState(false);
    const [app_top_bar_title, set_app_top_bar_title] = useState('');
    const [dash_fav_id, set_dash_fav_id] = useState('');
    const prevNotificationRef = useRef();
    const [isActiveSession, setIsActiveSession] = useState(false);
    const [isUserBranchDisabled, setIsUserBranchDisabled] = useState(false);
    const [synced_user_locations, set_synced_user_locations] = useState([]);
    const [isFirstLoginRequest, setIsFirstLoginRequest] = useState(false);

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => { }, [prevNotificationRef.current]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        if (
            user_details &&
            user_details.role_type !== 'Customer Admin' &&
            user_details.role_type !== 'Customer'
        ) {
            if (
                selected_branch &&
                user_locations_with_branches.locations &&
                user_locations_with_branches.locations.hasOwnProperty(
                    selected_branch
                )
            ) {
                set_user_branch_locations([
                    { value: 'all', label: 'All' },
                    ...user_locations_with_branches.locations[selected_branch],
                ]);
                localStorage.setItem('selectedBranch', selected_branch);
            } else {
                set_user_branch_locations([]);
            }
        }
    }, [selected_branch]);

    useEffect(() => {
        if (
            user_branches &&
            user_branches.length &&
            !show_customer_locations_dropdown
        ) {
            let selectedBranch =
                (user_details && user_details.defaultBranch) ||
                user_branches[0].value;
            const storageBranch = localStorage.getItem('selectedBranch');
            if (
                !user_details?.isImpersonate &&
                storageBranch &&
                user_branches
                    .map((branch) => branch.value)
                    .includes(storageBranch * 1)
            ) {
                selectedBranch = storageBranch;
            }
            setSelectedBranch(selectedBranch);
        } else {
            setSelectedBranch(null);
        }
    }, [user_branches]);

    useEffect(() => {
        if (
            user_locations_with_branches &&
            user_locations_with_branches.branches &&
            user_locations_with_branches.branches.length &&
            !show_customer_locations_dropdown
        ) {
            set_user_branches(user_locations_with_branches.branches);
        }
        if (
            user_details &&
            user_details?.type !== 'sprague' &&
            user_locations_with_branches.length
        ) {
            set_user_branch_locations(user_locations_with_branches);
        }
    }, [user_locations_with_branches]);

    useEffect(() => {
        if (user_details) {
            fetchUserLocationsInContext();
            if (user_details?.type !== 'sprague') {
                set_firstVisitAfterLogin(true);
            }
            /*
            let requestUrl = `/api/v1/${user_details?.isImpersonate ? `user-impersonate/location-and-branch-lookup/${user_details.userID}` : 'location/location-and-branch-lookup'}`;
            
            const getUserBranchesAndLocationList = () => API.get(requestUrl)
                .then((branchAndLocationList) => {
                    if (!branchAndLocationList.error && branchAndLocationList.branches.length) {
                        setUserLocationsWithBranches(branchAndLocationList);
                    } else {
                        setUserLocationsWithBranches({});
                    }
                    return branchAndLocationList;
                });
            getUserBranchesAndLocationList(); 
            */

            /* prev branch locations fetch */
        }
    }, [user_details]);

    useEffect(() => {
        if (isFirstLoginRequest) {
            const getMyLocations = () =>
                API.get('/api/v1/dashboard/location-status')
                    .then((locations) => {
                        if (!locations.error) {
                            const syncLocation = locations.map((l) => ({
                                ...l,
                                status: 'Syncing...',
                            }));
                            set_synced_user_locations(syncLocation);
                        }
                        const locationIDs = locations.map((l) => l.id);
                        return handleSyncLocations(locationIDs);
                    })
                    .catch((error) => {
                        return error;
                    });
            getMyLocations();
        }
        return () => setIsFirstLoginRequest(false);
    }, [isFirstLoginRequest]);

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    async function handleSyncLocations(locations) {
        const requestUrl = '/api/v1/pestpac/sync';

        const response = await API.post(
            requestUrl,
            { locations },
            false,
            false
        );

        const syncLocation = synced_user_locations.map((l) => ({
            ...l,
            status: !response.error ? 'Synced Successfully' : 'Not Synced',
            syncDate: !response.error ? response.syncDate : l.syncDate,
        }));
        set_synced_user_locations(syncLocation);
        return syncLocation;
    }

    const handleWindowSizeChange = () => {
        if (window.innerWidth <= 768) {
            set_isMobile(true);
        } else {
            set_isMobile(false);
        }
    };

    function fetchUserLocationsInContext() {
        if (user_details?.type === 'sprague') {
            let requestUrl = `/api/v1/${user_details?.isImpersonate
                    ? `user-impersonate/location-and-branch-lookup/${user_details.userID}`
                    : 'location/location-and-branch-lookup'
                }`;

            const getUserBranchesAndLocationList = () =>
                API.get(requestUrl).then((branchAndLocationList) => {
                    if (
                        !branchAndLocationList.error &&
                        branchAndLocationList.branches.length
                    ) {
                        setUserLocationsWithBranches(branchAndLocationList);
                    } else {
                        setUserLocationsWithBranches({});
                    }
                    return branchAndLocationList;
                });
            getUserBranchesAndLocationList();
            if (user_details.reSetAppTopBar) {
                set_show_branch_dropdown(true);
                set_show_location_dropdown(false);
                set_show_customer_locations_dropdown(false);
            }
        } else {
            const show_grouped_locations_options =
                user_details.role_type === 'Customer Admin';
            let requestUrl = `/api/v1/${user_details?.isImpersonate
                    ? `/user-impersonate/${show_grouped_locations_options
                        ? `location-groups-lookup`
                        : `locations-lookup`
                    }/${user_details.userID}`
                    : `${show_grouped_locations_options
                        ? `location-group`
                        : `location`
                    }/lookup`
                }`;
            const getCustomerLocations = () =>
                API.get(requestUrl).then((response) => {
                    if (!response.error) {
                        const mapFromLookupToComponentFormat = (options) =>
                            options
                                .map((group) => {
                                    if (group.value === 'all') {
                                        delete group['locations'];
                                        group['group'] = 'all';
                                        return group;
                                    } else {
                                        return group.locations.map(
                                            (location) => ({
                                                group: group.name,
                                                value: location.value,
                                                label: location.label,
                                                address: location.address,
                                                branch: location.branch,
                                            })
                                        );
                                    }
                                })
                                .flat();
                        let customerLocationsOptions = [
                            {
                                name: 'all',
                                value: 'all',
                                label: 'Select All',
                                address: '',
                                locations: [],
                            },
                            ...response,
                        ];
                        customerLocationsOptions = [
                            ...(show_grouped_locations_options
                                ? mapFromLookupToComponentFormat(
                                    customerLocationsOptions
                                )
                                : customerLocationsOptions),
                        ];

                        setUserLocationsWithBranches(customerLocationsOptions);
                    }
                });
            getCustomerLocations();
            if (user_details.reSetAppTopBar) {
                set_show_branch_dropdown(false);
                set_show_location_dropdown(false);
                set_show_customer_locations_dropdown(false);
            }
        }
    }

    async function checkAuthSession() {
        const userInfo = await API.get('/api/v1/auth');
        return userInfo;
    }

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    /* ContextProvider value
    -------------------------------------------------------------------------------------*/
    const data = {
        auth: [isAuth, setIsAuth],
        frame: [var_visibleFrame, set_visibleFrame],
        drawerOpen: [var_drawer_open, set_drawer_open],
        userData: [user_details, setUserDetails],
        userBranches: [user_branches],
        userBranchLocations: [user_branch_locations, set_user_branch_locations],
        refresh_data: [var_refresh_data, set_refresh_data],
        loading_data: [isLoadingData, set_loading_data],
        mobileCheck: [isMobile, set_isMobile],
        checkAuth: checkAuthSession,
        firstVisitAfterLogin: [var_firstVisitAfterLogin, set_firstVisitAfterLogin],
        selectedBranch: [selected_branch, setSelectedBranch],
        selectedBranchLocations: [
            selected_branch_locations,
            set_selected_branch_locations,
        ],
        selectedAllLocations: [
            selected_all_locations,
            set_selected_all_locations,
        ],
        showAppTopBarBranchField: [
            show_branch_dropdown,
            set_show_branch_dropdown,
        ],
        showCustomerLocationsDropdown: [
            show_customer_locations_dropdown,
            set_show_customer_locations_dropdown,
        ],
        showAppTopBarLocationField: [
            show_location_dropdown,
            set_show_location_dropdown,
        ],
        showServiceBinderLink: [
            show_service_binder_link,
            set_show_service_binder_link,
        ],
        showLogbookLink: [
            show_logbook_account_link,
            set_show_logbook_account_link,
        ],
        appTopBarTitleAsPerPage: [app_top_bar_title, set_app_top_bar_title],
        current_user_branches_and_locations: [
            user_locations_with_branches,
            setUserLocationsWithBranches,
        ],
        primaryUser: [primary_user, setPrimaryUser],
        secondaryUser: [secondary_user, setSecondaryUser],
        dashboardFavId: [dash_fav_id, set_dash_fav_id],
        activeSession: [isActiveSession, setIsActiveSession],
        userBranchDisabled: [isUserBranchDisabled, setIsUserBranchDisabled],
        strongPassFlow: [isStrongPassFlow, setIsStrongPassFlow],
        syncedUserLocations: [synced_user_locations, set_synced_user_locations],
        // fetchUpdatedUserLocationsInContext
        fetchUserLocationsInContext,
        syncMyLocations: [isFirstLoginRequest, setIsFirstLoginRequest],
    };

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return <AppContext.Provider value={data}>{children}</AppContext.Provider>;
};

const useData = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useData can only be used inside AppContext');
    }
    return context;
};

export { ContextProvider, useData };
